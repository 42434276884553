// CORE
import React from "react";
import classNames from "classnames";
// MUI
import { Box, IconButton, ListItem, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// HELPERS
import { getRelativeTime } from "../../../../../../../../helpers/getRelativeTime";
// CONSTANTS
import { notificationStatuses } from "../../../../../../../../constants/notification";

const NotificationItem = ({ item, onDeleteNotification, onClickItem }) => (
  <ListItem onClick={onClickItem} data-id={item._id} className="notifications__item">
    <Box className="notifications__content">
      <Typography variant="subtitle2" component="p" className={classNames("notifications__message", {
        unread: item.status === notificationStatuses.unread
      })}>
        {item.message}
      </Typography>
      <Typography variant="subtitle2" component="p" className="notifications__date">
        {getRelativeTime(item.createdAt)} ago
      </Typography>
    </Box>
    <Box className="notifications__actions">
      <IconButton disabled={item.disabled} onClick={onDeleteNotification} data-id={item._id}
                  className="notifications__delete">
        <DeleteIcon/>
      </IconButton>
    </Box>
  </ListItem>
)

export default NotificationItem;
