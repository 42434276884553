// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import updateMany from '../../helpers/store/updateMany';
import updateOne from '../../helpers/store/updateOne';

const fixUrl = (url) => String(url || "")
  .replace(/^[^\w]+/g, '')
  .replace(/[^\w]+$/g, '')
;

const fixApp = (app) => ({
  ...app,
  urlAddress: fixUrl(app.urlAddress),
});


const appSlice = createSlice({
  name : 'apps',
  initialState : {
    items     : [],
    current   : null,
    isLoading : true
  },
  reducers : {
    setCurrentApp(state, { payload }) {
      return ({
        ...state,
        current: fixApp(payload),
      })
    },
    updateApp(state, { payload }) {
      return ({
        ...state,
        items: state.items.map((app) => (app)._id === payload._id ? { ...(app),...payload } : (app))
      });
    },
    deleteApp(state, { payload }) {
      const index = state.items.findIndex((app) => app._id === payload._id);
      if (index === -1) return state;
      return ({
        ...state,
        items: state.items.slice(0, index).concat(state.items.slice(index + 1))
      });
    }
  },
  extraReducers : builder => builder
    .addCase(asyncActions.getApps.fulfilled, (state, { payload }) => ({
      ...state,
      items     : updateMany(state.items, payload.data),
      isLoading : false
    }))
    .addCase(asyncActions.getApps.pending, (state) => ({
      ...state,
      isLoading : true
    }))
    .addCase(asyncActions.getApps.rejected, (state) => ({
      ...state,
      isLoading : false
    }))
    .addCase(asyncActions.getByTeam.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateMany(state.items, payload.data)
    }))
    .addCase(asyncActions.getByProject.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateMany(state.items, payload.data)
    }))
    .addCase(asyncActions.cancelApp.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateOne(state.items, payload.data),
    }))
});

const appsStore = Object.freeze({
  ...appSlice,
  asyncActions,
});

export default appsStore;
