// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import teamService from '../../services/team';

export default ({
  setCurrentTeam     : createAsyncThunk('post/setCurrentTeam', teamService.setCurrentTeam),
  setCurrentTeamById : createAsyncThunk('post/setCurrentTeamById', teamService.setCurrentTeamById),
  getTeamById        : createAsyncThunk('get/getTeamById', teamService.getTeamById),
  createTeam         : createAsyncThunk('post/createTeam', teamService.createTeam),
  updateTeam         : createAsyncThunk('post/updateTeam', teamService.updateTeam),
  removeFromTeam     : createAsyncThunk('post/registerTeam', teamService.removeFromTeam),
  cancelInvite       : createAsyncThunk('post/cancelInvite', teamService.cancelInvite),
  getInviteList      : createAsyncThunk('get/inviteList', teamService.getInviteList),
  getCurrentTeam     : createAsyncThunk('get/currentTeam', teamService.getCurrentTeam),
  getTeams           : createAsyncThunk('get/teams', async () => teamService.getTeams()),
  getTeamResources   : createAsyncThunk('get/teamResources', async (params) => teamService.getTeamResources(params)),
  moveResources      : createAsyncThunk('post/moveResources', async (params) => teamService.moveResources(params))
});
