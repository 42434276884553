// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";
import getId from "../../helpers/fp/models/getId";

const getVirtualMachines       = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/virtual-machines`);
const getVirtualMachine        = (vmId) => getTrap(`/virtual-machines/${vmId}`);
const stopVirtualMachine       = (vmId) => axiosInstance.put(`/virtual-machines/${vmId}/stop`);
const startVirtualMachine      = (vmId) => axiosInstance.put(`/virtual-machines/${vmId}/start`);
const deleteVirtualMachine     = ({ vmId }) => axiosInstance.delete(`/virtual-machines/${vmId}`);
const createVirtualMachines    = (data) => axiosInstance.post(`/virtual-machines`, data);
const cancelVirtualMachine     = ({ id }) => axiosInstance.put(`/virtual-machines/${id}/cancel`);
const getRules                 = (vmId) => getTrap(`/virtual-machines/${vmId}/security-rules `);

const getVirtualMachinesByTeam    = (team)    => getTrap(`/teams/${getId(team)}/virtual-machines`);
const getVirtualMachinesByProject = (project) => getTrap(`/projects/${getId(project)}/virtual-machines`);

const virtualMachineService = Object.freeze({
  getVirtualMachines,
  getVirtualMachine,
  createVirtualMachines,
  deleteVirtualMachine,
  stopVirtualMachine,
  startVirtualMachine,
  cancelVirtualMachine,
  getRules,

  getByTeam    : getVirtualMachinesByTeam,
  getByProject : getVirtualMachinesByProject
});

export default virtualMachineService;
