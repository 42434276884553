// CORE
import { Link, useLocation } from "react-router-dom";
import React, { useState } from "react";

// MUI
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography
} from "@mui/material";
// ICONS
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// CONSTANTS
import { itemList } from "./constants";

import DataStorage from "../../../../../../../helpers/DataStorage";

const according = new DataStorage({ name: "accordingManage" });

const AccordionManage = () => {
  const location = useLocation();
  const {
    0 : accordionExpanded,
    1 : setAccordionExpanded,
  } = useState(!(+(according.value)));

  const handleExpand = () => {
    setAccordionExpanded(!accordionExpanded);
    according.value = Number(accordionExpanded);
  };

  return (
    <Accordion expanded={accordionExpanded} className="side-bar__accordion">
      <AccordionSummary
        sx={{ minHeight : '64px' }}
        className="side-bar__summary"
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={handleExpand}
      >
        <Typography variant="h6">Manage</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {itemList.map((item) => (
            <ListItem key={item.title} className={item.isDisabled ? 'disabled' : ''} disablePadding>
              <Link to={!item.isDisabled ? item.link : location.pathname}>
                <ListItemButton>
                  <ListItemText primary={item.title}/>
                </ListItemButton>
              </Link>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionManage;
