// core
import axiosInstance, { getTrap } from "../axiosInstance";

// helpers
import concatPath_ from "../../helpers/fp/url/concatPath_";
import concatPaths from "../../helpers/fp/url/concatPaths";

export const BILLING_SETTINGS_PATH = "/billing_settings";
const RETRIEVE_PATH                = "/retrieve";
const PAYMENT_METHOD_PATH          = "/payment_methods";
export const billingSettingsPath   = concatPath_(BILLING_SETTINGS_PATH);

/**
  * @param {object} params
  * @param {string} params.settingsId
  * @param {Record<PropertyKey, any>} params.settingsData
  * @returns {Promise<import("axios").AxiosResponse<import("../../types/BillingSettings").default>>}
  */
const updateSettings = ({ _id, ...billingSettings }) => axiosInstance
  .put(billingSettingsPath(_id), billingSettings);

const setPaymentMethod = ({
  billingSettingsId,
  paymentMethodId,
  returnUrl,
}) => axiosInstance.post(concatPaths([BILLING_SETTINGS_PATH, billingSettingsId, PAYMENT_METHOD_PATH, paymentMethodId]), { returnUrl });

/**
  * @returns {Promise<import("axios").AxiosResponse<import("../../types/BillingSettings").default>>}
  */
const createBillingSettings = (settingsData) => axiosInstance
  .post(BILLING_SETTINGS_PATH, settingsData);

/**
  * @returns {Promise<import("axios").AxiosResponse<import("../../types/BillingSettings").default>[]>}
  */
const getSettings = () => getTrap(BILLING_SETTINGS_PATH);

/**
  * @param {string} billingSettingsId
  * @returns {Promise<import("axios").AxiosResponse<import("../../types/BillingSettings").default>>}
  */
const getSettingsById = (billingSettingsId) => getTrap(billingSettingsPath(billingSettingsId));

const retrieve = (billingSettingsId) => getTrap(concatPaths([BILLING_SETTINGS_PATH, billingSettingsId, RETRIEVE_PATH]));

const getPaymentMethodsByBillingSettingsId = (billingSettingsId) => getTrap(concatPaths([BILLING_SETTINGS_PATH, billingSettingsId, PAYMENT_METHOD_PATH]));

const deletePaymentMethodById = ({ billingSettingsId, paymentMethodId }) => axiosInstance.delete(concatPaths([
  BILLING_SETTINGS_PATH,
  billingSettingsId,
  PAYMENT_METHOD_PATH,
  paymentMethodId,
]));

const setDefaultPaymentMethod = ({ billingSettingsId, paymentMethodId }) => axiosInstance.put(concatPaths([BILLING_SETTINGS_PATH, billingSettingsId, PAYMENT_METHOD_PATH, paymentMethodId]))

const billingSettingsApi = Object.freeze({
  createBillingSettings,
  getSettings,
  getSettingsById,
  updateSettings,
  retrieve,
  getPaymentMethodsByBillingSettingsId,
  deletePaymentMethodById,
  setPaymentMethod,
  setDefaultPaymentMethod
});

export default billingSettingsApi;
