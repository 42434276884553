import { createSlice }            from '@reduxjs/toolkit';
import asyncCloudProvidersActions from './asyncActions';

const cloudProviderSlice = createSlice({
  name : "cloudProvider",
  initialState : [],
  reducers: {},
  extraReducers: builder => builder
    .addCase(asyncCloudProvidersActions.list.fulfilled, (_state, { payload }) => payload.data)
  ,
});

/** @type {Readonly<typeof secretSlice & { asyncActions: typeof asyncSecretActions>} */
const cloudProviderStore = Object.setPrototypeOf(cloudProviderSlice, {
  asyncActions: asyncCloudProvidersActions,
});

export default cloudProviderStore;
