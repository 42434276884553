// AXIOS
import getId from "../../helpers/fp/models/getId";
import concatPaths from "../../helpers/fp/url/concatPaths";
import { getTrap } from "../axiosPublicInstance";

import { PROVIDER_PATH } from "../provider";

export const IMAGE_PATH = "images";

const getImagesByProvider = (provider) => getTrap(concatPaths([PROVIDER_PATH, getId(provider), IMAGE_PATH]));

const imagesApi = Object.freeze({
  getImagesByProvider,
});

export default imagesApi;
