// CORE
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// STORE
import projectStore from "../../store/project";

import indexed from "../../helpers/fp/array/indexed";
import useCurrentTeamId from "../teams/useCurrentTeamId";

/**
  * @typedef {import("../../types/Project").default} Project
  */

const indexedById = indexed("_id");

/**
  * @returns {readonly Project[]}
  */
const useCurrentTeamProjects = () => {
  const dispatch = useDispatch();
  const teamId   = useCurrentTeamId()
  const projects = useSelector((state) => state.project.items);

  useLayoutEffect(() => {
    if (!teamId) return;

    dispatch(projectStore.asyncActions.getProjects({
      teamId,
    }));

  }, [teamId]);

  useLayoutEffect(() => {
    if (!projects.length) return;

    const projectId = localStorage.getItem('project');

    if (projectId) {
      const currentProject = indexedById(projects)(projectId);

      if (!currentProject) {
        dispatch(projectStore.actions.setDefaultProject(projects[0]));
        localStorage.setItem('project', projects[0]._id);
        return;
      }

      dispatch(projectStore.actions.setDefaultProject(currentProject));
      return;
    }

    dispatch(projectStore.actions.setDefaultProject(projects[0]));
    localStorage.setItem('project', projects[0]._id);
  }, [projects]);

  return projects;
};

export default useCurrentTeamProjects;
