var eq = (value, next) => {
  if (next === value) return true;
  if (value && next && typeof value === "object" && typeof next === "object") {
    var values = new Set(), key;
    for (key in value) {
      if (eq(next[key], value[key])) {
        values.add(key);
        continue;
      }
      return false;
    }

    for (key in next) {
      if (values.has(key)) continue;
      return false;
    }
    return true;
  }
  return false;
};

export default eq;
