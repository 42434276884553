// AXIOS
import { getTrap } from "./axiosInstance";

export const PROVIDER_PATH = "providers";

/**
  * @typedef {import("../../types/Provider").default} Provider
  */

const getProviders = ((params) => getTrap(`/${PROVIDER_PATH}?type=${params.type}`));

/**
  * @param {Provider["type"]} type
  * @returns {Promise<import("axios").AxiosResponse<(Provider)[]>>}
  */
const getProvidersByType = (type) => getTrap(`/${PROVIDER_PATH}?type=${type}`);

const providerService = Object.freeze({
  getProviders,
  getProvidersByType,
});

export default providerService;
