import axiosPublicInstance from "../axiosPublicInstance";

/**
  * @returns {Promise<import("axios").AxiosResponse<import("../../types/CloudProvider").default[]>>}
  */
const list = () => axiosPublicInstance.get("/cloud-providers");

const cloudProviderApi = Object.freeze({
  list,
});

export default cloudProviderApi;

