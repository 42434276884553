import { prop } from "lodash/fp";
import { useSelector } from "react-redux";

const getRetrieves = prop("retrieves");

const useRetrieves = () => {
  const retrieves = useSelector(getRetrieves);
  return retrieves;
};

export default useRetrieves;
