// CORE
import React from "react";
import { Link } from "react-router-dom";
// MUI
import { Alert, Container } from "@mui/material";

import teamGetOwner from "../../../../../helpers/fp/models/team/getOwner";
import isSuspendedTeam from "../../../../../helpers/fp/models/team/isSuspended";
import userIsSuspended from "../../../../../helpers/fp/models/user/isSuspended";

const profileIsOwner = (profile, owner) => profile?._id === owner?._id;

const getMessage = (team, profile) => {
  if (!team) return null;

  const owner = teamGetOwner(team);

  if (userIsSuspended(owner) && profileIsOwner(profile, owner)) {
    return "Your account is suspended";
  }

  if (isSuspendedTeam(team)) {
    return "This team is suspended";
  }

  return null;
}

const SuspendTip = ({
                      profile,
                      team,
                    }) => {
  const message = getMessage(team, profile);

  if (!message) return null;

  return (
    <Container maxWidth="lg" style={{ marginBottom: "1rem" }}>
      <Alert severity="warning">
        {message}, check the <Link className="inherit" style={{ fontWeight: "bold", textDecoration: "underline" }}
                                   to="/billing">payment</Link> of your subscription.
      </Alert>
    </Container>
  );
};

export default SuspendTip;
