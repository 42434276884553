// CORE
import { createSlice } from '@reduxjs/toolkit';
import partition from 'lodash/fp/partition';

// ACTIONS
import asyncActions from './asyncActions';
import empty        from '../../helpers/fp/array/empty';
import eq    from '../../helpers/fp/array/internal/eq';
import DataCache from '../../helpers/DataCache';

const {
  save,
  load
} = DataCache("providers");

const providersSlice = createSlice({
  name         : "providers",
  initialState : load() || empty(),
  reducers     : {
    clearProviders: empty,
  },
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getProvidersByType.fulfilled, (state, { payload, meta }) => {
        const type = meta.arg;
        const [byType, byAnotherType] = partition((provider) => type === provider.type, state);
        if (eq(byType, payload.data)) return state;
        return save(byAnotherType.concat(payload.data));
      })
  },
});

const providersStore = Object.freeze({
  ...providersSlice,
  asyncActions,
});

export default providersStore;
