import indexed from "../../../helpers/fp/array/indexed";
import useBillingSettings from "../useBillingSettings";

const indexedById = indexed("_id");

/**
  * @typedef {import("../../../types/BillingSettings").default} BillingSettings
  */

/**
  * @param {BillingSettings["_id"]} billingSettingsId
  * @returns {BillingSettings | null}
  */
const useBillingSettingsById = (billingSettingsId) => {
  const billingSettings = useBillingSettings();

  if (billingSettingsId) {
    const findBillingSettingsById  = indexedById(billingSettings);
    const foundBillingSettingsById = findBillingSettingsById(billingSettingsId);
    return foundBillingSettingsById;
  }

  return null;
};

export default useBillingSettingsById;
