// CORE
import { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
// STORE
import useFetchTeams       from "./useFetchTeams";
import useFetchCurrentTeam from "./useFetchCurrentTeam";
import useAuthUser from "../user/useAuthUser";
import empty from "../../helpers/fp/array/empty";

const useTeams = () => {
  const user             = useAuthUser();
  const userId           = user?._id;
  const teams            = useSelector((state) => state.team.items);
  const fetchTeams       = useFetchTeams();
  const fetchCurrentTeam = useFetchCurrentTeam();

  useLayoutEffect(() => {
    if (!userId) return;
    fetchTeams();
    fetchCurrentTeam();
  }, [userId]);

  if (!teams?.length) return empty();
  return teams.filter((team) => team?.members?.some((member) => member.user._id === userId));
};

export default useTeams;
