// CORE
import { Link, useLocation } from "react-router-dom";
import React from "react";
// MUI
import { Accordion, AccordionDetails, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
// CONSTANTS
import { listItems } from "./constants";
import { settingsRoute } from "../../../../../../../constants/routes/routes";
import useIsCurrentOwner from "../../../../../../../hooks/teams/useIsCurrentOwner";
import useCurrentTeam from "../../../../../../../hooks/teams/useCurrentTeam";


const AccordionAccount = () => {
  const location = useLocation();
  const team     = useCurrentTeam();
  const isOwner  = useIsCurrentOwner();

  return (
    <Accordion className="side-bar__accordion">
      <AccordionDetails className="side-bar__details_account">
        <List>
          {listItems.map((item, index) => {
            const isDisabled = item.isDisabled || (index === 1 && !isOwner);
            return (
            <ListItem key={item.title} className={isDisabled ? 'disabled' : ''} disablePadding>
                {/* eslint-disable-next-line */}
              <Link to={isDisabled ? location.pathname : (item.link === settingsRoute ? `${settingsRoute}/${team?._id}` : item.link)}>
                <ListItemButton>
                  <ListItemText primary={item.title}/>
                </ListItemButton>
              </Link>
            </ListItem>
          )})}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionAccount;
