/* eslint-disable no-undef */
export default (dsn) => {
  if (!dsn) return console.warn("Sentry DSN is not provided");

  window.addEventListener("DOMContentLoaded", () => {
    var sentryScript = document.createElement("script");
    sentryScript.async = true;
    sentryScript.src = "https://browser.sentry-cdn.com/7.93.0/bundle.min.js";
    document.body.appendChild(sentryScript);

    sentryScript.onload = () => {
      Sentry.init({
        dsn,
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
          }),
          new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false,
          }),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      });
    };
  });
};

