import { useEffect } from "react";

// hooks
import useRetrieves                        from "../useRetrieves";
import useFetchRetrieveByBillingSettingsId from "../useFetchRetrieveByBillingSettingsId";

// helpers
import Null    from "../../../helpers/fp/function/Null";
import indexed from "../../../helpers/fp/array/indexed";

const indexedByBillingSettingsId = indexed("billingSettingsId");

/**
  * @typedef {import("../../../types/BillingSettings").default} BillingSettings
  * @typedef {import("../../../types/Retrieve").default} Retrieve
  */

/**
  * @param {BillingSettings["_id"]} [billingSettingsId]
  * @returns {Retrieve | null}
  */
const useRetrieveByBillingSettingsId = (billingSettingsId) => {
  const retrieves = useRetrieves();

  const findRetrieveByBillingSettingsId = billingSettingsId
    ? indexedByBillingSettingsId(retrieves)
    : Null
  ;

  const foundRetrieve                    = findRetrieveByBillingSettingsId(billingSettingsId);
  const fetchRetrieveByBillingSettingsId = useFetchRetrieveByBillingSettingsId();

  useEffect(() => {
    if (billingSettingsId) fetchRetrieveByBillingSettingsId(billingSettingsId);
  }, [billingSettingsId]);

  return foundRetrieve;
};

export default useRetrieveByBillingSettingsId;
