// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from '../../helpers/fp/array/empty';
import updateManyBy from '../../helpers/store/updateManyBy';

const sizesSlice = createSlice({
  name         : 'sizes',
  initialState : empty(),
  reducers     : {},
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getSizes.fulfilled, updateManyBy((reducer) => reducer.payload.data))
      .addCase(asyncActions.getSizesByImageId.fulfilled, updateManyBy((reducer) => reducer.payload.data))
    ;
  },
});

const sizesStore = Object.freeze({
  ...sizesSlice,
  asyncActions,
});

export default sizesStore;
