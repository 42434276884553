import getId from "../../helpers/fp/models/getId";
import { getTrap } from "../axiosInstance";

const TEAM_PATH        = "teams";
const ACTUAL_COST_PATH = "actual-costs";

const actualCostApi = Object.freeze({
  getByTeam: (team) => getTrap(`${TEAM_PATH}/${getId(team)}/${ACTUAL_COST_PATH}`),
})
;

export default actualCostApi;
