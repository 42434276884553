// CORE
import { createSlice } from "@reduxjs/toolkit";
// ACTIONS
import asyncActions from "./asyncActions";

const billingAlertSlice = createSlice({
  name: "billingAlert",
  initialState: {
    current: null,
    isLoading: false,
  },
  reducers: {
    clearBillingAlert: (state) => {
      state.current = null;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(asyncActions.getBillingAlert.fulfilled, (state, { payload }) => {
        state.current = payload.data;
        state.isLoading = false;
      })
      .addCase(asyncActions.getBillingAlert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getBillingAlert.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.createBillingAlert.fulfilled, (state, { payload }) => {
          state.current = payload.data;
          state.isLoading = false;
      })
      .addCase(asyncActions.createBillingAlert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.createBillingAlert.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.deleteBillingAlert.fulfilled, (state) => {
        state.current = null;
        state.isLoading = false;
      })
      .addCase(asyncActions.deleteBillingAlert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.deleteBillingAlert.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.updateBillingAlert.fulfilled, (state, { payload }) => {
          state.current = payload.data;
          state.isLoading = false;
      })
      .addCase(asyncActions.updateBillingAlert.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.updateBillingAlert.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default {
  ...billingAlertSlice,
  asyncActions,
};
