import memoizeWeak from "../memoize/weak";

export default memoizeWeak((call) => {
  const values = new Map;
  return (value) => {
    if (values.has(value)) return values.get(value);
    const result = Promise.resolve(call(value));
    values.set(value, result);
    return result.finally(() => values.delete(value));
  }
});
