import AppsIcon from "@mui/icons-material/Apps";
import { Storage } from "@mui/icons-material";

export const CLOUD_PROVIDERS = {
  AZURE: "Azure",
  AWS: "AWS",
  GCP: "GCP",
  OVH: "OVH",
  KRAUD: "Kraud.Cloud",
};

export const RESOURCE_TYPES = {
  APP: "app",
  DATABASE: "database",
  VM: "vm",
};

export const RESOURCE_TYPES_FORMATTED = {
  [RESOURCE_TYPES.APP]: {
    name: "App",
    icon: AppsIcon,
  },
  [RESOURCE_TYPES.DATABASE]:
    {
      name: "Database",
      icon: Storage,
    },
};

export const FILE_TYPES = {
  BASE64: "base64",
  TEXT: "text"
}

export const RESOURCE_SECRET_TYPES = {
  SSH_PW: "ssh-pw",
  SSH_KEY: "ssh-key",
}
