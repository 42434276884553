import memoize     from "../../decorators/memoize";
import memoizeWeak from "../../decorators/memoize/weak";
import Null from "../../function/Null";

var findIndexed = (target, key, values, value, length) => {
  var index  = target.size;
  if (index < length) {
    var set = values[index];
    var is  = set[key];
    target.set(is, set);
    if (is === value) return set;
    while (++index < length) {
      set = values[index];
      is  = set[key];
      target.set(is, set);
      if (is === value) return set;
    }
  }
  return null;
};

export default memoize((key) => memoizeWeak((values) => {
  var length = values.length;
  if (length === 0) return Null;
  var target = new Map();
  return (value) => target.get(value) || findIndexed(target, key, values, value, length);
}));
