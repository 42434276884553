// CORE
import { createSlice } from '@reduxjs/toolkit';

import partition from 'lodash/fp/partition';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import DataCache from '../../helpers/DataCache';

import empty       from '../../helpers/fp/array/empty';
import eq          from '../../helpers/fp/array/internal/eq';
import memoize     from '../../helpers/fp/decorators/memoize';
import memoizeWeak from '../../helpers/fp/decorators/memoize/weak';

const locationProviderIdIs    = (providerId) => (location) => location.provider === providerId;
const partitionByProviderIdIs = memoize((providerId) => memoizeWeak(partition(locationProviderIdIs(providerId))));

const {
  save,
  load
} = DataCache("regions");

const regionsSlice = createSlice({
  name : 'regions',
  initialState : load() || empty(),
  reducers : {
    clearSizes: empty,
  },
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getLocationsByProviderId.fulfilled, (state, { payload, meta }) => {
        if (!payload.data.length) return state;
        if (!state.length)        return save(payload.data);

        const providerId = meta.arg;
        const [byArgs, byStore] = partitionByProviderIdIs(providerId)(state);

        if (!byArgs.length)  return save(state.concat(payload.data));
        if (!byStore.length) return save(payload.data);

        if (eq(byArgs, payload.data)) return state;
        return save(byStore.concat(payload.data));
      });
  },
});

const regionsStore = Object.freeze({
  ...regionsSlice,
  asyncActions,
});

export default regionsStore;
