import { useDispatch } from "react-redux";
import teamStore from "../../store/team";
import useAuthUserId from "../user/useAuthUserId";

const useFetchCurrentTeam = () => {
  const dispatch = useDispatch();
  const userId = useAuthUserId();

  return () => userId
    ? dispatch(teamStore.asyncActions.getCurrentTeam())
    : Promise.resolve(null);
}

export default useFetchCurrentTeam;
