import { createSlice } from '@reduxjs/toolkit';

import asyncByosActions from './asyncActions';

import empty     from '../../helpers/fp/array/empty';
import eqArrays  from '../../helpers/fp/array/internal/eq';
import eqObjects from '../../helpers/fp/object/internal/eq';
import DataCache from '../../helpers/DataCache';

const {
  save,
  load
} = DataCache("byos");

const updateByosItem = (state, { payload }) => {
  const byos = payload.data;
  const byosId = byos._id;
  const index = state.findIndex(({ _id }) => _id === byosId);
  return index > -1
    ? eqObjects(state[index], byos)
      ? state
      : state.slice(0, index).concat(byos, state.slice(index + 1))
    : state.concat(byos);
};

const byosSlice = createSlice({
  name          : "byos",
  initialState  : load() || empty(),
  extraReducers : builder => builder
    .addCase(asyncByosActions.list.fulfilled, (state, { payload }) => {
      const { data } = payload;
      if (!data.length)  return state;
      if (!state.length) return save(data);
      if (eqArrays(data, state)) return state;
      return save(data);
    })
  // .addCase(asyncByosActions.unsubscribe.pending, changeSubscribeStatus(false))
    .addCase(asyncByosActions.unsubscribe.fulfilled, updateByosItem)
  // .addCase(asyncByosActions.subscribe.pending, changeSubscribeStatus(true))
    .addCase(asyncByosActions.subscribe.fulfilled, updateByosItem)
  ,
});

const byosStore = Object.freeze({
  ...byosSlice,
  asyncActions: asyncByosActions,
});

export default byosStore;
