import {
  appRoute,
  databaseRoute,
  KUBERNETES_ROUTE,
  virtualMachineRoute,
} from "../../../../../../../../constants/routes/routes";


/** @see https://github.com/orgs/enclaive/projects/6/views/1?pane=issue&itemId=36020963 */
export const itemList = [
  {
    title      : 'Apps',
    link       : appRoute,
    isDisabled : false,
  },
  {
    title      : 'Databases',
    link       : databaseRoute,
    isDisabled : false,
  },
  {
    title      : 'Virtual Machines',
    link       : virtualMachineRoute,
    isDisabled : false,
  },
  {
    title      : 'Kubernetes',
    link       : KUBERNETES_ROUTE,
    isDisabled : false,
  },
  {
    title      : 'Functions',
    link       : '/functions',
    isDisabled : true,
  },
];
