// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from "../../constants/pagination";


const notificationSlice = createSlice({
  name: 'notification',
  initialState: {
    items: [],
    info: { ...defaultPagination, totalUnreadDocs: 0 },
    current: null,
    isLoading: false
  },
  reducers: {
    setCurrentNotification(state, { payload }) {
      state.current = payload;

      return state;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getNotifications.fulfilled, (state, { payload }) => {
        const { docs, ...pagination } = payload.data;
        const uniqueDocs = docs.filter((doc) => !state.items.some((item) => item._id === doc._id));

        switch (pagination.page) {
          case 1:
            state.items = docs;
            break;
          default:
            state.items = [...state.items, ...uniqueDocs]
        }

        state.info = { ...pagination }
        state.isLoading = false;
      })
      .addCase(asyncActions.getNotifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getNotifications.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.markAsRead.fulfilled, (state, action) => {
        state.items = state.items.map(item => item._id !== action.meta.arg.id ? item : ({
          ...item,
          status: 1
        }))
        state.info = { ...state.info, totalUnreadDocs: state.info.totalUnreadDocs - 1 };
      })
      .addCase(asyncActions.deleteNotifications.pending, (state, action) => {
        state.items = state.items.map(item => item._id !== action.meta.arg.id ? item : ({ ...item, disabled: true }))
      })
      .addCase(asyncActions.deleteNotifications.rejected, (state, action) => {
        state.items = state.items.map(item => item._id !== action.meta.arg.id ? item : ({ ...item, disabled: false }))
      })
  },
});

export default ({
  ...notificationSlice,
  asyncActions,
});
