import { always, complement } from "lodash/fp";
import { CONFIRM_ROUTE, NEEDS_ROUTE, paymentVerificationRoute } from "../../constants/routes/routes";

import needsApi    from "../../api/needs";

import something   from "../../helpers/fp/composition/something";
import getPathName from "../../helpers/fp/window/getPathName";
import isSkipNeeds from "../../helpers/fp/localStorage/isSkip/isSkipNeeds";
import propData    from "../../helpers/fp/models/propData";
import notEmpty    from "../../helpers/fp/array/notEmpty";
import either      from "../../helpers/fp/logic/either";
import call        from "../../helpers/fp/function/call";
import resolveTrue from "../../helpers/fp/promise/resolveTrue";

const isAcceptNoRedirect          = () => [CONFIRM_ROUTE].includes(getPathName());
const isAcceptNoNeedsPath         = () => isAcceptNoRedirect() && [paymentVerificationRoute, NEEDS_ROUTE].includes(getPathName());

const neNeedNeeds = something([isAcceptNoNeedsPath, isSkipNeeds]);
const isNeedNeeds = complement(neNeedNeeds);
const isSetNeeds  = () => needsApi.get().then(propData).then(notEmpty);

const useNeNeedNeeds = () => () => isNeedNeeds()
  ? isSetNeeds().then(always).then(either(neNeedNeeds)).then(call)
  : resolveTrue
;

export default useNeNeedNeeds;
