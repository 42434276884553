// CORE
import { Link, useLocation } from "react-router-dom";
import React from "react";
// MUI
import { List, ListItem } from "@mui/material";
// STYLES
import './style.scss'
// CONSTANTS
import { footerItems } from "./constants";

const Index = () => {
  const location = useLocation();
  return (
    <footer className="footer-component">
      <List>
        {footerItems.map(item => (
          <ListItem key={item.title} className={item.isDisabled ? 'disabled' : ''}>
            <Link to={item.isDisabled ? location.pathname : item.link}>{item.title}</Link>
          </ListItem>
        ))}
      </List>
    </footer>
  );
};

export default Index;
