// hooks
import useNotPaymentVerifiedByBillingSettingsId from "../useNotPaymentVerifiedByBillingSettingsId";

// helpers
import pipe       from "../../../helpers/fp/composition/pipe";
import optionalId from "../../../helpers/fp/models/optionalId";
import lift       from "../../../helpers/fp/monads/lift";

const useNotPaymentVerifiedByBillingSettings = pipe([
  optionalId,
  lift,
  useNotPaymentVerifiedByBillingSettingsId,
]);

export default useNotPaymentVerifiedByBillingSettings;
