// AXIOS
import { z } from "zod";
import getId from "../../helpers/fp/models/getId";
import axiosInstance, { getTrap } from "../axiosInstance";
import databaseCreateSchema from "../../schemas/database/create";

const getDatabases   = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/databases`);
const getDatabase    = (dbId) => getTrap(`/databases/${dbId}`);
const deleteDatabase = ({ dbId }) => axiosInstance.delete(`/databases/${dbId}`);

const createDatabase = z
  .function()
  .args(databaseCreateSchema)
  .strictImplement((databases) => axiosInstance.post(`/databases`, databases))
;

const cancelDatabase = ({ id }) => axiosInstance.put(`/databases/${id}/cancel`);

const getDatabasesByTeam    = (team)    => getTrap(`/teams/${getId(team)}/databases`);
const getDatabasesByProject = (project) => getTrap(`/projects/${getId(project)}/databases`);

const databaseApi = Object.freeze({
  getDatabases,
  getDatabase,
  deleteDatabase,
  createDatabase,
  cancelDatabase,

  getByTeam    : getDatabasesByTeam,
  getByProject : getDatabasesByProject
});

export default databaseApi;
