import emptyArray from "../../helpers/fp/array/empty";
import emptyObject from "../../helpers/fp/object/empty";
import eq from "../../helpers/fp/object/internal/eq";

import {
  REACTIVATE_USER,
  SET_NOTIFICATIONS,
  SET_USER_DATA,
  SET_USER_DATA_IS_LOADING,
} from "./actionTypes";

const initialState = {
  userDataIsLoading: false,
  userData: emptyObject(),
  notifications: emptyArray(),
};

// eslint-disable-next-line
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA: {
      if (eq(action.payload, state.userData)) return state;
      return {
        ...state,
        userData: action.payload,
      };
    }

    case REACTIVATE_USER:
      return {
        ...state,
        userData: { ...state.userData, status:action.payload },
      };

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };

    case SET_USER_DATA_IS_LOADING:
      return action.payload !== state.userDataIsLoading
        ? { ...state, userDataIsLoading: action.payload }
        : state;

    default:
      return state;
  }
};

export default userReducer;
