/**
  * @template {((value) => any) => any} Call
  * @param {Call} exec
  * @returns {ReturnType<Parameters<Call>>>}
  */
var lift = (exec) => {
  var value;
  return (exec(is => value = is), value);
}

export default lift;
