import { createSlice } from '@reduxjs/toolkit';

import assoc  from "lodash/fp/assoc"
import prop   from "lodash/fp/prop"
import uniqBy from "lodash/fp/uniqBy"

import { findIndex } from 'lodash/fp';
import empty from '../../helpers/fp/array/empty';

import asyncPaymentMethodsActions from './asyncActions';

const assocBillingSettingsId    = assoc("billingSettingsId");
const uniqById                  = uniqBy(prop("id"));
const notExistBillingSettingsId = (billingSettingsId) => (paymentMethod) => paymentMethod.billingSettingsId !== billingSettingsId

const waitDelete = new Map();

const idIs = (id) => (paymentMethod) => paymentMethod.id === id;
const findIndexById = (id) => findIndex(idIs(id));

const paymentMethodSlice = createSlice({
  name          : "paymentMethod",
  initialState  : [],
  reducers      : {
    clean            : empty,
    addPaymentMethod : (state, { payload }) => state.concat(payload),
    verifiedMethod   : (state, { payload }) => state.map((method) => method.id === payload.id ? ({ ...method, ...payload, noVerify: false }) : method),
  },
  extraReducers : builder => builder
    .addCase(asyncPaymentMethodsActions.getPaymentMethodsByBillingSettingsId.fulfilled, (state, { payload, meta }) => {
      const billingSettingsId = meta.arg;
      return uniqById(state
        .filter(notExistBillingSettingsId(billingSettingsId))
        .concat(payload.data.map(assocBillingSettingsId(billingSettingsId)))
      );
    })
    .addCase(asyncPaymentMethodsActions.deletePaymentMethodById.pending, (state, { meta }) => {
      const { paymentMethodId } = meta.arg;
      const findCurrentIndex = findIndexById(paymentMethodId);
      const index = findCurrentIndex(state);
      if (index > -1) {
        waitDelete.set(paymentMethodId, state[index]);
        return state.slice(0, index).concat(state.slice(index + 1));
      }
      return state;
    })
    .addCase(asyncPaymentMethodsActions.deletePaymentMethodById.fulfilled, (state, { meta }) => {
      const { paymentMethodId } = meta.arg;
      const findCurrentIndex = findIndexById(paymentMethodId);
      const index = findCurrentIndex(state);
      waitDelete.delete(paymentMethodId);
      if (index > -1) return state.slice(0, index).concat(state.slice(index + 1));
      return state;
    })
    .addCase(asyncPaymentMethodsActions.deletePaymentMethodById.rejected, (state, { meta }) => {
      const { paymentMethodId } = meta.arg;
      if (waitDelete.has(paymentMethodId)) {
        const paymentMethod = waitDelete.get(paymentMethodId);
        waitDelete.delete(paymentMethodId);
        const findCurrentIndex = findIndexById(paymentMethodId);
        const index = findCurrentIndex(state);
        if (index > -1) return state.slice(0, index).concat(paymentMethod, state.slice(index + 1));
        return state.concat(paymentMethod);
      }
    })
  ,
});

const paymentMethodStore = Object.freeze({
  ...paymentMethodSlice,
  asyncActions: asyncPaymentMethodsActions,
});

export default paymentMethodStore;
