import { useSelector } from "react-redux";
import useCurrentTeamProjects from "./useCurrentTeamProject";

const useCurrentProject = () => {
  const project  = useSelector((state) => state.project.current);
  const projects = useCurrentTeamProjects();
  return project || projects[0];
}

export default useCurrentProject;
