import { filter, prop } from "lodash/fp";

import is    from "../../helpers/fp/logic/is";
import getId from "../../helpers/fp/models/getId";
import pipe  from "../../helpers/fp/composition/pipe";

const selectActualCosts = (state) => state.actualCosts;
const log = (value) => (console.log(value), value);
const selectActualCostsByTeam = (team) => pipe([selectActualCosts, filter(pipe([prop("teamId"), is(log(getId(team)))]))]);

export default selectActualCostsByTeam;
