// CORE
import React, { useCallback } from "react";
// MUI
import { Box, Typography } from "@mui/material";
// STYLES
import './styles.scss';
// HELPERS
import { downloadFileByURL } from "../../../helpers/downloadFile";
// COMPONENTS
import ModalWindow from "../ModalWindow";
import Button from "../../common/Button";
import { convertToEuro } from "../../../helpers/convertToEuro";

const InvoicePaidModal = ({ onClose, notification }) => {
  const handleDownloadInvoice = useCallback(() => {
    if (!notification.params.invoicePdf) return;

    downloadFileByURL({ url: notification.params.invoicePdf, name: 'invoice.pdf' })
  }, [notification])

  return <ModalWindow {...{ open: true, setOpen: onClose, title: 'Successful card payment notification' }}>
    <Box className="invoice-paid-modal">
      <Typography component="p" className="invoice-paid-modal__subtitle">
        Payment of €{convertToEuro(notification.params.total)} to Enclaive GmbH was successful
      </Typography>
      <Typography component="p" className="invoice-paid-modal__description">
        Thank you for your payment to <Box component="span"
                                           fontWeight={600}>Enclaive
        GmbH</Box>. Your transaction has been successfully processed.
        <br/>
        A detailed confirmation email has been sent to your registered email address. Please check your inbox for more
        information regarding your recent payment.
      </Typography>
      <Button onClick={handleDownloadInvoice} className="invoice-paid-modal__btn">Download Invoice</Button>
    </Box>
  </ModalWindow>
}

export default InvoicePaidModal;
