import always from "lodash/fp/always";
import axiosInstance from "./axiosInstance";

const PATH = always("/attestations/tracking");

const attestationApi = Object.freeze({
  /**
  * @param {object} props
  * @param {string} props.userId
  */
  trigger : ({ CSP, type }) => axiosInstance
    .post(PATH(), { type, CSP }),

  getAttestationById : ({ id }) => axiosInstance.get(`/attestations/${id}`),
});

export default attestationApi;
