import findBy_id from "../../fp/models/findBy_id";

const updateMany = (state, values) => {
  if (values.length === 0) return state;
  if (state.length === 0)  return values;

  const create = values.reduce((create, next) => {
    const id = next._id;
    const current = findBy_id(id)(state);
    if (current && current.updatedAt === next.updatedAt) return create;
    create.set(id, next);
    return create;
  }, new Map());

  return create.size
    ? state.filter((value) => !create.has(value._id)).concat(Array.from(create.values()))
    : state
  ;
};

export default updateMany;
