import { createAsyncThunk } from "@reduxjs/toolkit";

import { billingSettingsApi } from "../../api";
import teamService from "../../services/team";

const asyncRetrieveActions = Object.freeze({
  getByBillingSettingsId : createAsyncThunk("retrieves/getByBillingSettingsId", billingSettingsApi.retrieve),
  getByTeamId            : createAsyncThunk("retrieves/getByTeamId", teamService.retrieve),
});

export default asyncRetrieveActions;
