// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import storageApi from '../../api/storage';

const userStorageAsyncActions = Object.freeze({
  get    : createAsyncThunk('get/storage',    storageApi.get),
  set    : createAsyncThunk('set/storage',    storageApi.set),
  delete : createAsyncThunk('delete/storage', storageApi.delete),
});

export default userStorageAsyncActions;
