// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
import notificationApi from "../../api/notification";
// SERVICES


export default ({
  getNotifications: createAsyncThunk('get/notifications', notificationApi.getNotifications),
  deleteNotifications: createAsyncThunk('delete/notification', notificationApi.deleteNotification),
  markAsRead: createAsyncThunk('put/markAsRead', notificationApi.markAsRead),
});
