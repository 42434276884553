// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';
import eq           from '../../helpers/fp/object/internal/eq';
import ENV          from '../../constants/ENV';
import DataCache    from '../../helpers/DataCache';
import empty        from '../../helpers/fp/object/empty';

const {
  save,
  load
} = DataCache("public_config");

const setPublicConfig = (state, { payload }) => eq(payload, state) ? state : save(payload);

const publicConfigSlice = createSlice({
  name         : "providers",
  initialState : load() || ({
    kraudIngress : ENV.REACT_APP_DOMAIN || "",
    hubspot      : false,
    recapcha     : false,
    "AWS"        : false,
    "Azure"      : false,
    "GCP"        : false,
    "Kraud.Cloud": false,
  }),
  reducers : empty(),
  extraReducers : builder => builder
    .addCase(asyncActions.getPublicConfig.fulfilled, setPublicConfig)
  ,
});

const publicConfigStore = Object.freeze({
  ...publicConfigSlice,
  asyncActions,
});

export default publicConfigStore;
