// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
// CONSTANTS
import { defaultPagination } from "../../constants/pagination";


const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    items: [],
    lastInvoice:null,
    info: defaultPagination,
    isLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getInvoices.fulfilled, (state, { payload }) => {
        const { docs, ...pagination } = payload.data;
        state.items = docs;
        state.info = { ...pagination }

        state.isLoading = false;
      })
      .addCase(asyncActions.getInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getInvoices.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.getLastInvoice.fulfilled, (state, { payload }) => {
        state.lastInvoice = payload.data;

        state.isLoading = false;
      })
      .addCase(asyncActions.getLastInvoice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getLastInvoice.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.retryInvoice.fulfilled, (state, { payload }) => {
        const { id } = payload.data._id;

        state.items = state.items.map(invoice => invoice._id === id ? ({ ...invoice, status: 'open' }) : invoice)
      })
      .addCase(asyncActions.retryInvoice.pending, (state, { meta }) => {
        const { id } = meta.arg;

        state.items = state.items.map(invoice => invoice._id === id ? ({ ...invoice, status: 'open' }) : invoice)
      })
      .addCase(asyncActions.retryInvoice.rejected, (state, { meta }) => {
        const { id } = meta.arg;

        state.items = state.items.map(invoice => invoice._id === id ? ({ ...invoice, status: 'failed' }) : invoice)
      })
  },
});

export default ({
  ...invoiceSlice,
  asyncActions,
});
