// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import projectService from '../../services/project';


export default ({
  deleteProject: createAsyncThunk('delete/project', async (data) => projectService.deleteProject(data)),
  getProjectResources: createAsyncThunk('get/projectResources', async (data) => projectService.getProjectResources(data)),
  createProject: createAsyncThunk('post/projects', async (data) => projectService.createProject(data)),
  getProjects: createAsyncThunk('get/projects', async (params) => projectService.getProjects(params)),
  updateProject: createAsyncThunk('put/project', async (params) => projectService.updateProject(params)),
});
