import getOwner from "../fp/models/team/getOwner";

export const getTeamName = (name) => {
  if(!name) return '';
  const arr = name.trim().split(' ');
  if (arr[1]) return `${arr[0][0]}${arr[1][0]}`
  return name.substr(0, 2).trim();
};

export const getUserAbbr = (user) => {
  const { name } = user;
  if (name) {
    const {
      0: first,
      1: last,
    } = name.trim().split(/\s+/);

    if (last) return `${first[0]}${last[0]}`;
    return name.substr(0, 2).trim();
  }

  return '';
}

export const getTeamOwnerName = (team) => {
  const owner = getOwner(team);
  if (owner) {
    const abbr = getUserAbbr(owner);
    if (abbr) return abbr;
  }
  return getTeamName(team?.name);
}
