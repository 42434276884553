export default (key) => ({
  save: (data) => (localStorage.setItem(key, JSON.stringify(data)), data),
  load: () => {
    try {
      return JSON.parse(localStorage.getItem(key))
    } catch (e) {
      localStorage.removeItem(key)
      return null;
    }
  }
});
