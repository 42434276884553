import { toast } from "react-toastify";

const errors = new Set();

const showError = (error) => {
  if (!error && errors.has(error)) return;
  errors.add(error);
  toast.error(error)
  setTimeout(() => errors.delete(error), 3000);
};

export default showError;
