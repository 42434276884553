import axios from "axios";
import ENV           from "../constants/ENV";
import trap          from "../helpers/fp/decorators/trap";

const baseURL = ENV.REACT_APP_API_PUBLIC_URL;

const metaVersion = document.head.querySelector("meta[name=version]");
const metaDate    = document.head.querySelector("meta[name=date]");

const axiosPublicInstance = axios.create({
  baseURL,
  mode: "no-cors",
  headers: {
    "Content-Type" : "application/json",
    "EMCP-Version" : metaVersion?.content || "unknown",
    "EMCP-DATE"    : metaDate?.content || "unknown",
  },
});

axiosPublicInstance.interceptors.request.use(
  (config) => {
    // Get the maintenance key from local storage
    const maintenanceKey = localStorage.getItem('maintenanceKey');

    // If the key exists, add it to the headers
    if (maintenanceKey) {
      config.headers['X-Maintenance-Key'] = maintenanceKey;
    }

    // Return the updated config
    return config;
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

export const getTrap = trap((url) => axiosPublicInstance.get(url));
export default axiosPublicInstance;
