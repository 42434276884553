import axiosInstance, { getTrap } from "../axiosInstance";

/**
  * @param {object} props
  * @param {string} props.teamId
  */
const list = ({ teamId }) => getTrap(`/teams/${teamId}/subscriptions`);

/**
  * @param {object} props
  * @param {string} props.teamId
  * @param {string} props.cloudProviderId
  */
const subscribe = ({
  teamId,
  cloudProviderId,
}) => axiosInstance.post(`/teams/${teamId}/subscriptions/${cloudProviderId}`);

/**
  * @param {object} props
  * @param {string} props.teamId
  * @param {string} props.cloudProviderId
  */
const unsubscribe = ({
  teamId,
  cloudProviderId,
}) => axiosInstance.delete(`/teams/${teamId}/subscriptions/${cloudProviderId}`);

const validateBYOS = ({ teamId, data }) => axiosInstance.post(`/teams/${teamId}/subscriptions`, data);

const byosApi = Object.freeze({
  list,
  subscribe,
  unsubscribe,
  validateBYOS
});

export default byosApi;
