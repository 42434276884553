// CORE
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
// MUI
import { Box, Typography } from "@mui/material";
// STYLES
import './styles.scss';
// HELPERS
// COMPONENTS
import ModalWindow from "../ModalWindow";
import Button from "../../common/Button";
import { convertToEuro } from "../../../helpers/convertToEuro";

const InvoiceFailedModal = ({ onClose, notification }) => {
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    onClose();
    navigate('/billing');
  }, [])

  return <ModalWindow {...{ open: true, setOpen: onClose, title: 'Failed card payment notification' }}>
    <Box className="invoice-failed-modal">
      <Typography component="h4" className="invoice-failed-modal__subtitle">
        €{convertToEuro(notification.params.total)} payment to Enclaive GmbH was unsuccessful
      </Typography>
      <Typography component="p" className="invoice-failed-modal__description">
        We were unable to charge your <Box component="span" textTransform="capitalize"
                                           fontWeight={600}>{notification.params.card.brand}</Box> ending in <Box
        component="span" fontWeight={600}>{notification.params.card.last4}</Box> for your <Box component="span"
                                                                                               fontWeight={600}>Enclaive
        GmbH</Box> payment.
        Please update your payment information to complete your purchase.
      </Typography>
      <Button onClick={handleClick} className="invoice-failed-modal__btn">Update payment method</Button>
    </Box>
  </ModalWindow>
}

export default InvoiceFailedModal;
