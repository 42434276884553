import { z } from "zod";

const databaseCreateSchema = z
  .object({
    project      : z.string().length(24),
    size         : z.string().length(24),
    imageVersion : z.string().length(24),
    hostNames    : z.array(z.string()).min(1),
    datacenter   : z.string(),
    location     : z.string(),
    subscription : z.string().optional(),
    authUser     : z.string(),
    tags         : z.array(z.string()),
  })
;

const attestationSchema = z.object({
  completed: z.boolean(),
  id       : z.string().uuid(),
  success  : z.boolean(),
});

const imageSchema = z.object({
  distribution : z.string(),
  varsion      : z.object({
    name        : z.string(),
    displayName : z.string(),
  }),
});

const regionSchema = z.object({
  datacenter: z.object({
    name: z.string(),
    displayName: z.string(),
  }),
  physicalLocation: z.string(),
});

export const databaseCreateSchemaResult = z.object({
  _id              : z.string().length(24),
  imageVersion     : z.string().length(24),
  ipConfigurations : z.array(z.object()),
  isBlocked        : z.boolean().optional(),
  name             : z.string(),
  owner            : z.object(),
  port             : z.string().optional(),
  price            : z.string().length(24),
  project          : z.string().length(24),
  provider         : z.string().length(24),
  size             : z.string().length(24),
  status           : z.number(),
  subscription     : z.string().or(z.null()).optional(),
  tags             : z.array(z.string()),
  team             : z.string().length(24),

  createdAt        : z.string(),
  updatedAt        : z.string(),
  cancelledAt      : z.string().or(z.null()).optional(),
  deletedAt        : z.string().or(z.null()).optional(),

  attestation      : attestationSchema,
  image            : imageSchema,
  region           : regionSchema,
});

export default databaseCreateSchema;
