// CORE
import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

// MUI
import { CssBaseline, ThemeProvider } from "@mui/material";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// STYLES
import "./styles/main.scss";
import { muiTheme } from "./assets/theme";
import "react-toastify/dist/ReactToastify.css";

// SLICK CAROUSEL STYLES`
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// HOOKS
import useTeams               from "./hooks/teams/useTeams";
import useCurrentTeamProjects from "./hooks/projects/useCurrentTeamProject";
import useSocket              from "./hooks/socket/useSocket";

// COMPONENTS
import AppRouter          from "./AppRouter";
import PageLoader         from "./components/shared/PageLoader";
import ScrollToTop        from "./components/common/ScrollToTop";
import ServerModeProvider from "./providers/ServerModeProvider";

import zero from "./helpers/fp/number/zero";
import inc  from "./helpers/fp/number/inc";
import { themesQueryOptions } from "./queries/themes/useThemeQuery";


const queryClient = new QueryClient();

const App = () => {
  const { 0: key, 1: setKey } = useState(zero);
  window[Symbol.for("forceUpdate")] = () => setKey(inc);

  useEffect(() => {
    queryClient.prefetchQuery(themesQueryOptions());
  }, []);

  useTeams();
  useCurrentTeamProjects();
  useSocket({ queryClient });

  return (
    <ThemeProvider
      key={key}
      theme={muiTheme}
    >
      <CssBaseline />
      <ToastContainer
        autoClose={2000}
        closeOnClick
        draggable={false}
        hideProgressBar
        limit={3}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-center"
        rtl={false}
        theme="colored"
        style={{
          fontSize: "16px",
          maxWidth: "600px",
          width: "auto",
        }}
      />
      <Suspense fallback={<PageLoader />}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <ScrollToTop />
            <ServerModeProvider>
              <AppRouter />
            </ServerModeProvider>
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
