// CORE
import { createSlice } from '@reduxjs/toolkit';
import empty from '../../helpers/fp/array/empty';
// ACTIONS
import asyncActions from './asyncActions';


const attestationSlice = createSlice({
    name : 'attestation',
    initialState : {
      items     : empty(),
      isLoading : false
    },
    reducers : {
      clearCurrentAttestation(state) {
        state.items = empty();
        return state;
      },
    },
    extraReducers : builder => builder
      .addCase(asyncActions.getAttestationById.fulfilled, (state, { payload }) => {
        state.items = payload.data;
        state.isLoading = false;
      })
      .addCase(asyncActions.getAttestationById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getAttestationById.rejected, (state) => {
        state.isLoading = false;
      })
});

const attestationStore = Object.freeze({
  ...attestationSlice,
  asyncActions,
});

export default attestationStore;
