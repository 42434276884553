// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from "../../helpers/fp/array/empty";
import updateOneBy from '../../helpers/store/updateOneBy';

const pricesSlice = createSlice({
  name : 'prices',
  initialState : empty(),
  reducers : {},
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getPriceBySizeId.fulfilled, updateOneBy((reducer) => reducer.payload.data))
    ;
  },
});

const pricesStore = Object.freeze({
  ...pricesSlice,
  asyncActions,
});

export default pricesStore;
