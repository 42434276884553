// CORE
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// MUI
import { Box, Button, List, ListItem, Popover } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// STYLES
import './menuCreateProducts.scss'
// CONSTANTS
import { productsList } from "./constants";
import { userStatuses } from "../../../../../../../constants/user";
import { teamRoles } from "../../../../../../../constants/team";

const MenuCreateProducts = () => {
  const team = useSelector((state) => state.team.current);
  const [link, setLink] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const owner = team?.members.find(member => member.role === teamRoles.owner);
  const isDisabled = owner?.user?.status !== userStatuses.active;

  useEffect(() => {
    if (anchorEl) return;
    if (link) navigate(link)
    setLink(null)
  }, [anchorEl])

  const handleClick = (event) => isDisabled ? null : setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleClickItem = (item) => {
    setAnchorEl(null)
    if (!item.isDisabled) setLink(item.to)
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box>
      <Button
        className={`button-menu ${anchorEl ? 'up' : ''}`}
        variant="contained"
        size="medium"
        endIcon={<ExpandMoreIcon />}
        disabled={isDisabled}
        onClick={handleClick}
      >
        Create
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className='button-popover'
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List className="menu-products">
          {productsList.map(item => {
            if (item.title === 'Divider') return <Box key={item.subTitle}>{item.element}</Box>
            return (
              <ListItem
                onClick={() => handleClickItem(item)}
                key={item.subTitle}
                className={item.isDisabled ? 'disabled' : ''}>
                <Box className="menu-products__link">
                  <Box
                    className="icon-create"
                  >
                    {item.element}
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box className="menu-products__title">{item.title}</Box>
                    <Box className="menu-products__description">{item.subTitle}</Box>
                  </Box>
                </Box>
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </Box>
  );
};

export default MenuCreateProducts;
