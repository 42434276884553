// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const activitySlice = createSlice({
    name : 'activity',
    initialState : {
        items : [],
        isLoading : false
    },
    reducers : {
        clearActivities(state) {
            state.items = [];

            return state;
        },
    },
    extraReducers : builder => {
        builder
            .addCase(asyncActions.getActivities.fulfilled, (state, { payload }) => {
                state.items = payload.data;
                state.isLoading = false;
            })
            .addCase(asyncActions.getActivities.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(asyncActions.getActivities.rejected, (state) => {
                state.isLoading = false;
            })
    },
});

export default ({
    ...activitySlice,
    asyncActions,
});
