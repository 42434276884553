// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// import { billingSettingsApi } from '../../api';

// SERVICES
import teamService from "../../services/team";
import { billingSettingsApi } from '../../api';

const billingSettingsAsyncActions = Object.freeze({
  createBillingSettings       : createAsyncThunk('post/createBillingSettings', billingSettingsApi.createBillingSettings),
  getBillingSettings          : createAsyncThunk('get/getBillingSettings', billingSettingsApi.getSettings),
  getBillingSettingsById      : createAsyncThunk('get/getBillingSettingsById', billingSettingsApi.getSettingsById),
  updateBillingSettings       : createAsyncThunk('put/updateBillingSettings', billingSettingsApi.updateSettings),

  updateBillingSettingsByTeam : createAsyncThunk('put/updateBillingSettingsByTeamId', teamService.updateBillingSettings),
  getBillingSettingsByTeam    : createAsyncThunk('get/getBillingSettingsByTeamId', teamService.getBillingSettings),
});

export default billingSettingsAsyncActions;
