import { useDispatch } from "react-redux";
import always from "lodash/fp/always"

import empty from "../helpers/fp/array/empty";

const resolveEmpty = always({ payload: empty() });

const createUseFetchElementsById = (action) => () => {
  const dispatch = useDispatch();
  return (id) => id ? dispatch(action(id)) : resolveEmpty;
};

export default createUseFetchElementsById;
