// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import k8sService from '../../services/k8s';

const k8sAsyncActions = Object.freeze({
  getVersions : createAsyncThunk('get/versions', k8sService.getVersions),
  getClusters : createAsyncThunk('get/clusters', k8sService.getClusters),
  getCluster  : createAsyncThunk('get/cluster', k8sService.getCluster),

  getByTeam    : createAsyncThunk('get/clustersByTeam', k8sService.getByTeam),
  getByProject : createAsyncThunk('get/clustersByProject', k8sService.getByProject),

  createCluster : createAsyncThunk('clusters/createCluster', k8sService.createCluster),
});

export default k8sAsyncActions;
