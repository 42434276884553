// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';
import empty from '../../helpers/fp/array/empty';


const userActivitySlice = createSlice({
  name          : 'userActivity',
  initialState  : empty(),
  extraReducers : builder => {
    builder
      .addCase(asyncActions.getUserActivities.fulfilled, (state, { payload }) => (payload.data.length && state.length)
        ? (payload.data.at(0).user !== state.at(0).user || payload.data.length > state.length)
          ? payload.data
          : state
        : payload.data.length
          ? payload.data
          : empty()
      )
  },
});

const userActivity = Object.freeze({
  ...userActivitySlice,
  asyncActions,
});

export default userActivity;
