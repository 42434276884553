import { toast } from "react-toastify";

const errors = new Set();
const showErrorMessage = (error, defaultMessage = "Something went wrong") => {
  let message = "";
  if (error?.response?.data?.message) message = error.response.data.message;
  else if (error?.message) message = error.message;
  else if (typeof error === "string") message = error;
  else message = defaultMessage;

  if (errors.has(message)) return;
  errors.add(message);
  toast.error(message);

  setTimeout(() => {
    errors.delete(message);
  }, 1000);

  return error;
};

export default showErrorMessage;
