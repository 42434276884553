// AXIOS
import concatPaths    from "../../helpers/fp/url/concatPaths";
import { getTrap }    from "../axiosPublicInstance";
import { IMAGE_PATH } from "../image";

export const SIZES_PATH = "sizes";

/**
  * @param {string} imageId
  * @returns {Promise<import("axios").AxiosResponse<import("../../types/Size").default>>}
  */
const getSizesByImageId = (imageId) => getTrap(concatPaths([IMAGE_PATH, imageId, SIZES_PATH]));

const getSizes = (({ id, location, group }) => getTrap(`/providers/${id}/sizes/?location=${location}&group=${group}`));

const sizeApi = Object.freeze({
  getSizes,
  getSizesByImageId,
});

export default sizeApi;
