export default (paymentMethod) => {
  if (!paymentMethod) return false;
  const { card } = paymentMethod;
  if (!card) return false;
  const expMonth = paymentMethod.card.exp_month;
  const expYear  = paymentMethod.card.exp_year;
  const expDate  = new Date(expYear, expMonth, 1, 0, 0, 0, 0);
  const expTime  = expDate.getTime();
  return expTime > Date.now();
};
