import { useSelector }     from "react-redux";
import { useLayoutEffect } from "react";
import currentTeam         from "./currentTeam";
import useAuthUserId       from "../user/useAuthUserId";
import useFetchCurrentTeam from "./useFetchCurrentTeam";

/**
  * @typedef {import("../../types/Team").default} Team
  */

/** @returns {Team | null} */
const useCurrentTeam = () => {
  const team             = useSelector(currentTeam);
  const fetchCurrentTeam = useFetchCurrentTeam();
  const userId           = useAuthUserId();

  useLayoutEffect(() => {
    if (!team && userId) fetchCurrentTeam();
  }, [team]);

  /** @type {Team | null} */
  return (team);
};

export default useCurrentTeam;
