// AXIOS
import { getTrap } from "../../api/axiosPublicInstance";


/** @param {string} id */
const getLocationsByProviderId = (id) => getTrap(`/providers/${id}/locations`);

const regionService = Object.freeze({
  getLocationsByProviderId,
});

export default regionService;
