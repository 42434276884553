// CORE
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

// MUI
import { Box } from "@mui/material";

// STYLES
import "./dashboardLayout.scss";

// ICONS
import { Navigate } from "react-router-dom";

// CONSTANTS
import { notificationTypes } from "../../../constants/notification";

// STORE
import notificationStore from "../../../store/notification";

// ROUTES
import { AUTH_ROUTE, createTeamRoute } from "../../../constants/routes/routes";

// COMPONENTS
import DashboardContext   from "./DashboardContext";
import DashboardHeader    from "./components/DashboardHeader/DashboardHeader";
import DashboardOutlet    from "./components/DashboardOutlet/DashboardOutlet";
import DashboardSideBar   from "./components/DashboardSideBar/DashboardSideBar";
import DeactivateTip      from "./components/DeactivateTip";
import SuspendTip         from "./components/SuspendTip";
import SwitchTeam         from "./components/SwitchTeam";
import InviteModal        from "../../shared/InviteModal";
import InvoiceFailedModal from "../../shared/InvoceFailedModal";
import InvoicePaidModal   from "../../shared/InvocePaidModal";

// helpers
import teamGetOwner from "../../../helpers/fp/models/team/getOwner";
import useAuthUser  from "../../../hooks/user/useAuthUser";
import accessToken  from "../../../helpers/accessToken";

// hooks
import useOpenCompleteBilling from "../../shared/CompleteBillingWindow/useOpenCompleteBilling";
import useCurrentTeam         from "../../../hooks/teams/useCurrentTeam";

const CompleteBillingWindow = React.lazy(() =>
  import("../../shared/CompleteBillingWindow")
);

const DashboardLayout = () => {
  const team = useCurrentTeam();

  const {
    isOpen    : isOpenCompleteBillingWindow,
    setIsOpen : setIsOpenCompleteBillingWindow,
    verifyBilling,
    checkIsNotPaymentVerified,
  } = useOpenCompleteBilling(team);

  const contextData = useMemo(
    () => ({
      isOpenCompleteBillingWindow,
      setIsOpenCompleteBillingWindow,
      verifyBilling,
      checkIsNotPaymentVerified,
    }),
    [
      isOpenCompleteBillingWindow,
      setIsOpenCompleteBillingWindow,
      verifyBilling,
      checkIsNotPaymentVerified,
    ]
  );

  const profile = useAuthUser();
  const isNeedToCreateTeam = useSelector(
    (state) => state.team.isNeedToCreateTeam
  );
  const owner = team ? teamGetOwner(team) : null;
  const dispatch = useDispatch();
  const [nextTeam, setNextTeam] = useState(null);
  const [currentNotification, setCurrentNotification] = useState(null);

  const profileId = profile?._id;

  useLayoutEffect(() => {
    if (profileId)
      dispatch(
        notificationStore.asyncActions.getNotifications({ page: 1, limit: 10 })
      );
  }, [profileId]);

  const handleCloseModal = useCallback(() => setCurrentNotification(null), []);

  if (!profileId && !accessToken.value) return <Navigate to={AUTH_ROUTE} />;
  if (isNeedToCreateTeam)               return <Navigate to={createTeamRoute} />;

  return (
    <Box className="dashboard-layout">
      <CompleteBillingWindow
        open={isOpenCompleteBillingWindow}
        setOpen={setIsOpenCompleteBillingWindow}
      />
      <DashboardHeader
        setNextTeam={setNextTeam}
        setCurrentNotification={setCurrentNotification}
        className="dashboard-header"
      />
      <DashboardSideBar
        className="dashboard-side-bar"
      />
      <Box
        id="dashboard-wrapper"
        className="dashboard-wrapper"
        display="flex"
        flexDirection="column"
        height="100%"
        paddingTop="var(--offset-2)"
        paddingInline="var(--offset)"
        paddingBottom="var(--offset)"
      >
        <SuspendTip
          profile={profile}
          team={team}
        />
        <DeactivateTip
          owner={owner}
          profile={profile}
        />
        <DashboardContext.Provider
          value={contextData}
        >
          <DashboardOutlet />
        </DashboardContext.Provider>
      </Box>
      <SwitchTeam
        setNextTeam={setNextTeam}
        nextTeam={nextTeam}
      />
      {
        {
          [notificationTypes.invite]: (
            <InviteModal
              setNextTeam={setNextTeam}
              notification={currentNotification}
              onClose={handleCloseModal}
            />
          ),
          [notificationTypes.invoicePaid]: (
            <InvoicePaidModal
              notification={currentNotification}
              onClose={handleCloseModal}
            />
          ),
          [notificationTypes.invoiceFailed]: (
            <InvoiceFailedModal
              notification={currentNotification}
              onClose={handleCloseModal}
            />
          ),
        }[currentNotification?.type]
      }
    </Box>
  );
};

export default DashboardLayout;
