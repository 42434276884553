// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";
import getId from "../../helpers/fp/models/getId";

/** @depricated */
const getApps       = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/apps`);

const getApp        = (appId) => getTrap(`/apps/${appId}`);
const deleteApp     = ({ appId }) => axiosInstance.delete(`/apps/${appId}`);
const createApp     = (appData) => axiosInstance.post(`/apps`, appData);
const cancelApp     = ({ id }) => axiosInstance.put(`/apps/${id}/cancel`);

export const getAppsByTeam    = (team)    => getTrap(`/teams/${getId(team)}/apps`);
export const getAppsByProject = (project) => getTrap(`/projects/${getId(project)}/apps`);

const appService = Object.freeze({
  getApps,
  getApp,
  deleteApp,
  createApp,
  cancelApp,

  getByTeam    : getAppsByTeam,
  getByProject : getAppsByProject,
});

export default appService;
