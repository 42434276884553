// CORE
import React from "react";
// MUI
import { Box, Typography } from "@mui/material";
import { BugIllustration } from "../../../assets/Illustrations";
// STYLES
import './styles.scss';
// HOC
import withErrorState from "../../../hoc/withErrorState";
// COMPONENTS
import Button from "../../common/Button";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidUpdate(prevProps) {
    if (!this.props.hasError && prevProps.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch() {
    this.props.setHasError(true);
  }

  handleGoBack = () => this.props.navigate(-1);

  render() {
    if (this.state.hasError) {
      return (
        <Box className="error-page">
          <Typography className="error-page__title">Sorry, something went wrong</Typography>
          <Box className="error-page__icon">
            <BugIllustration/>
          </Box>
          <Button onClick={this.handleGoBack}>Go Back</Button>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default withErrorState(ErrorBoundary);
