import React from "react";

import { Avatar } from "@mui/material";

const TeamAvatar = ({
  teamName,
  ...props
}) => (
  <div {...props}>
    <Avatar className='dashboard-header__avatar'
      alt="Team name"
      sx={{ bgcolor : "#b458fc" }}
    >
      {teamName.toUpperCase()}
    </Avatar>
  </div>
);

export default TeamAvatar;
