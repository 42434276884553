import isSuspendedUser from "../../user/isSuspended";
import teamOwners from "../teamOwners";

/**
  * @returns {boolean}
  */
const teamIsAnySuspendedOwner = (team) => {
  const owners = teamOwners(team);
  return !(owners.length) || owners.some(isSuspendedUser);
}

export default teamIsAnySuspendedOwner;
