const { NODE_ENV } = process.env;

const PREFIX             = 'REACT_APP_';
const PREFIX_ENV         = `${PREFIX}${NODE_ENV.toUpperCase()}_`;
const PREFIX_PATTERN     = new RegExp(`^${PREFIX}`);
const PREFIX_ENV_PATTERN = new RegExp(`^${PREFIX_ENV}`);

const ENV = new Proxy(process.env, {
  get (target, key, receiver) {
    switch (key) {
      case 'NODE_ENV': return Reflect.get(target, key, receiver);
      default: {
        const envKey = key.replace(PREFIX_PATTERN, PREFIX_ENV);
        const value = Reflect.get(target, envKey, receiver);
        if (value) return value;
        const currentValue = Reflect.get(target, key, receiver)
        if (currentValue) return currentValue;
        const defaultKey = key.replace(PREFIX_ENV_PATTERN, PREFIX);
        return Reflect.get(target, defaultKey, receiver);
      };
    }
  }
});

window.ENV = ENV;

export default ENV;
