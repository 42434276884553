// CORE
import { Badge, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TrendingFlatIcon from "@mui/icons-material/TrendingFlat";
import React, { useCallback, useEffect } from "react";
// STYLES
import './styles.scss';
// STORE
import { useNavigate } from "react-router-dom";
import teamActions from "../../../../../store/team/actions";
import teamStore from "../../../../../store/team";
import projectStore from "../../../../../store/project";
// HELPERS
import { getTeamName } from "../../../../../helpers/team";
// COMPONENTS
import TeamAvatar from "../../../../common/TeamAvatar";
import useFetchCurrentTeam from "../../../../../hooks/teams/useFetchCurrentTeam";

const SwitchTeam = ({ nextTeam, setNextTeam }) => {
  const fetchCurrentTeam = useFetchCurrentTeam();
  const navigate = useNavigate();
  const team = useSelector((state) => state.team.current);
  const dispatch= useDispatch();

  const handleSwitchTeam = useCallback(async () => {
    if (!nextTeam) return;

    try {
      await teamActions.switchTeam({ teamId: nextTeam._id });
      const current = fetchCurrentTeam();
      navigate('/')
      dispatch(projectStore.actions.clearState());
      await current;
      dispatch(teamStore.actions.setCurrentTeam(nextTeam));
    } finally {
      setNextTeam(null)
    }

  }, [nextTeam])

  useEffect(() => {
    if (!nextTeam) return;

    handleSwitchTeam();

  }, [nextTeam])

  if (!team || !nextTeam) return null;

  return (
    <Box className="switch-window">
      <Box className="switch-window__content">
        <h4 className="switch-window__title">Switching teams...</h4>
        <Box className="switch-window__teams">
          <Badge
            sx={{ marginLeft: '16px' }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <TeamAvatar teamName={getTeamName(team?.name)}/>
          </Badge>
          <TrendingFlatIcon/>
          <Badge
            sx={{ marginLeft: '16px' }}
            overlap="circular"
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <TeamAvatar teamName={getTeamName(nextTeam?.name)}/>
          </Badge>
        </Box>
      </Box>
    </Box>
  );
};

export default SwitchTeam;
