import { createSlice } from '@reduxjs/toolkit';
import findIndex from "lodash/fp/findIndex"

import { assoc } from 'lodash/fp';
import asyncRetrieveActions from './asyncActions';
import asyncPaymentMethodsActions from '../paymentMethods/asyncActions';

import empty from '../../helpers/fp/array/empty';
import eq    from '../../helpers/fp/object/internal/eq';
import on    from '../../helpers/fp/function/on';

const onFindIndex                  = on(findIndex);
const existProp                    = (key) => (value) => (test) => test[key] === value;
const existTeamId                  = existProp("teamId");
const existBillingSettingsId       = existProp("billingSettingsId");
const findIndexByBillingSettingsId = onFindIndex(existBillingSettingsId);
const findIndexByTeamId            = onFindIndex(existTeamId);
const replaceNth                   = (index) => (value) => (values) => values.slice(0, index).concat(value, values.slice(index + 1));
const assocBillingSettingsId       = assoc("billingSettingsId");

const updateByIndex = (state, index, retrieve) => {
  if (index === -1) return state.concat(retrieve);
  const currentRetrieve = state[index];
  if (eq(currentRetrieve, retrieve)) return state;
  return replaceNth(index)(retrieve)(state);
};

const updateByBillingSettingsId = (state, billingSettingsId, retrieve) => {
  const retrieveIndex = findIndexByBillingSettingsId(billingSettingsId)(state);
  const assocCurrentBillingSettingsId = assocBillingSettingsId(billingSettingsId);
  return updateByIndex(state, retrieveIndex, assocCurrentBillingSettingsId(retrieve));
};

const updateByTeamId = (state, teamId, retrieve) => {
  const findIndexByCurrentTeamId = findIndexByTeamId(teamId);
  const retrieveIndex            = findIndexByCurrentTeamId(state);
  return updateByIndex(state, retrieveIndex, retrieve);
};

const retrieveSlice = createSlice({
  name          : "retrieves",
  initialState  : empty(),
  reducers      : {
    clean: empty,
  },
  extraReducers : builder => builder
    .addCase(asyncRetrieveActions.getByBillingSettingsId.fulfilled, (state, { payload, meta }) => {
      const billingSettingsId = meta?.arg || payload.billingSettingsId;
      return updateByBillingSettingsId(state, billingSettingsId, payload.data);
    })
    .addCase(asyncRetrieveActions.getByTeamId.fulfilled, (state, { payload, meta }) => {
      const retrieve = payload.data;
      const { billingSettingsId } = retrieve;
      if (billingSettingsId) return updateByBillingSettingsId(state, billingSettingsId, retrieve);
      const teamId                   = meta?.arg || payload.teamId;
      return updateByTeamId(state, teamId, retrieve);
    })
    .addCase(asyncPaymentMethodsActions.setDefaultPaymentMethod.fulfilled, (state, { payload, meta }) => {
      const billingSettingsId = meta?.arg?.billingSettingsId;
      return updateByBillingSettingsId(state, billingSettingsId, payload.data);
    })
});

const retrieveStore = Object.freeze({
  ...retrieveSlice,
  asyncActions: asyncRetrieveActions,
});

export default retrieveStore;
