import { toast } from "react-toastify";
import { compose } from "@reduxjs/toolkit";

import F from "lodash/fp/F";
import prop from "lodash/fp/prop";
import eq from "lodash/fp/equals";

// CONSTANTS
import {
  REACTIVATE_USER,
  SET_USER_DATA,
  SET_USER_DATA_IS_LOADING,
} from "./actionTypes";

import {
  ACCESSIBLE_UN_AUTH_ROUTES,
  AUTH_ROUTE,
} from "../../constants/routes/routes";

// api
import { authApi, userApi } from "../../api";
import inviteApi from "../../api/invite";

// helpers
import showErrorMessage from "../../helpers/showErrorMessage";
import accessToken from "../../helpers/accessToken";
import vaultTokenJWT from "../../helpers/vaultTokenJWT";
import { userStatuses } from "../../constants/user";
import vaultToken from "../../helpers/vaultToken";

export const reactivateUser = () => ({
  type: REACTIVATE_USER,
  payload: { status: userStatuses.active },
});

export const setUserData = (data) => ({
  type: SET_USER_DATA,
  payload: data,
});

const setUserDataIsLoading = (isLoading) => ({
  type: SET_USER_DATA_IS_LOADING,
  payload: isLoading,
});

/**
 * @param {object} credentials
 * @param {string} credentials.email
 * @param {string} credentials.password
 */
const login = (credentials) => (dispatch) =>
  authApi.login(credentials).then(({ data }) => {
    sessionStorage.clear();
    localStorage.clear();
    accessToken.value = data.userToken;
    vaultTokenJWT.value = data.vaultToken;
    vaultToken.pop();
    if (data.user) dispatch(setUserData(data.user));
    return data.user;
  });
const reactivate = () => (dispatch) =>
  authApi.reactivate().then(({ data }) => {
    if (data.user?.status === userStatuses.active) dispatch(reactivateUser());
  });
const authToken = (token) => (dispatch) =>
  authApi.authToken(token).then(({ data }) => {
    accessToken.value = data.userToken;
    vaultTokenJWT.value = data.vaultToken;
    vaultToken.pop();
    if (data.user) dispatch(setUserData(data.user));
  });
const refresh = (oldUserData) => (dispatch) =>
  authApi.refresh().then(({ data }) => {
    accessToken.value = data.userToken;
    vaultTokenJWT.value = data.vaultToken;
    vaultToken.pop();
    const { user } = data;
    if (user) {
      if (oldUserData && !eq(oldUserData, user)) {
        dispatch(setUserData(user));
      }
    }
  });
/**
 * @param {object} registerData
 * @param {string} registerData.email
 * @param {string} registerData.password
 * @param {string} [registerData.name]
 * @param {string} [registerData.capcha]
 * @param {string} [registerData.referrer]
 */
const register = (registerData) => (dispatch) =>
  authApi
    .register(registerData)
    .then(({ data }) => {
      accessToken.value = data.userToken;
      vaultTokenJWT.value = data.vaultToken;
      if (data.user) dispatch(setUserData(data.user));
    })
    .catch((error) => {
      accessToken.pop();
      vaultTokenJWT.pop();
      dispatch(setUserData(null));
      throw error;
    });

const confirmEmail = (token) => (dispatch) =>
  authApi.confirmEmail({ token }).then(({ data }) => {
    accessToken.value = data.userToken;
    vaultTokenJWT.value = data.vaultToken;
    vaultToken.pop();
    dispatch(
      setUserData({
        balance: 0,
        status: 0,
        name: "",
        email: "",
        emailVerified: true,
        ...data.user,
      })
    );
    return data;
  });
const switchTeam = async ({ teamId }) => {
  const { data } = await userApi.setCurrentTeam({ teamId });
  accessToken.value = data.userToken;
  vaultTokenJWT.value = data.vaultToken;
  vaultToken.pop();
};

/**
 * @param {object} params
 * @param {`${string}@${string}.${string}`} params.email
 * @param {string} params.password
 * @param {string} params.inviteId
 */
const acceptInvite = async ({ inviteId, email, password, name }) => {
  const { data } = await inviteApi.acceptInvite({
    inviteId,
    email,
    password,
    name,
  });

  if (data.userToken) accessToken.value = data.userToken;
  if (data.vaultToken) vaultTokenJWT.value = data.vaultToken;

  vaultToken.pop();
  return data;
};

const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  accessToken.pop();
  vaultTokenJWT.pop();
  vaultToken.pop();
  window.location.href = AUTH_ROUTE;
};

const fetchUserData = () => (dispatch) => (
  dispatch(setUserDataIsLoading(true)),
  userApi
    .getUserData()
    .then(compose(dispatch, setUserData, prop("data")))
    .catch(
      (error) =>
        ACCESSIBLE_UN_AUTH_ROUTES.includes(window.location.pathname) ||
        showErrorMessage(error)
    )
    .finally(compose(dispatch, setUserDataIsLoading, F))
);

const deactivateAccount = (data) => async (dispatch) => {
  try {
    await userApi.deactivateAccount(data);
    accessToken.pop();
    dispatch(setUserData({}));
  } catch (e) {
    showErrorMessage(e);
  } finally {
    dispatch(setUserDataIsLoading(false));
  }
};

const updateUserData = (dataToUpdate) => async (dispatch) => {
  const { data } = await userApi.updateUserData(dataToUpdate);

  dispatch(setUserData(data));
  setTimeout(() => toast.success("User data updated"), 250);
};

const userActions = Object.freeze({
  login,
  authToken,
  register,
  logout,
  acceptInvite,
  fetchUserData,
  updateUserData,
  deactivateAccount,
  switchTeam,
  confirmEmail,
  refresh,
  reactivate,
});

export default userActions;
