// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import providerService from '../../services/provider';


export default ({
    getProviders : createAsyncThunk('get/providers',
        async (params) => providerService.getProviders(params)),

  getProvidersByType : createAsyncThunk('get/providersByType', providerService.getProvidersByType),
});
