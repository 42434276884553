import { useEffect }   from "react";
import { useDispatch } from "react-redux";

// hooks
import useTeamById                     from "../../teams/useTeamById";
import useBillingSettingsById          from "../useBillingSettingsById";
import useFetchBillingSettingsByTeamId from "../useFetchBillingSettingsByTeamId";
import useIsCurrentOwner from "../../teams/useIsCurrentOwner";

// store
import teamStore from "../../../store/team";

/**
  * @typedef {import("../../../types/BillingSettings").default} BillingSettings
  */

/**
  * @param {string} [teamId]
  * @returns {BillingSettings | null}
  */
const useBillingSettingsByTeamId = (teamId) => {
  const dispatch                     = useDispatch();
  const { team }                     = useTeamById(teamId);
  const teamBillingSettings          = team?.billingSettings;
  const billingSettingsId            = teamBillingSettings?._id;
  const billingSettings              = useBillingSettingsById(billingSettingsId);
  const fetchBillingSettingsByTeamId = useFetchBillingSettingsByTeamId();
  const isOwner = useIsCurrentOwner();

  useEffect(() => {
    if (teamId && isOwner) fetchBillingSettingsByTeamId(teamId)
      .then(({ payload, meta }) => {
        if (meta.requestStatus === "fulfilled") {
          dispatch(teamStore.actions.updateBillingSettingsByTeamId({
            _id             : teamId,
            billingSettings : payload.data,
          }));
        }
      });
  }, [teamId,isOwner]);

  return billingSettings || teamBillingSettings;
};

export default useBillingSettingsByTeamId;
