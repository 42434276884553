// CORE
import React, { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// MUI
import { Box, List, Popover } from "@mui/material";
// CONSTANTS
import { notificationStatuses } from "../../../../../../../constants/notification";
// STORE
import notificationStore from "../../../../../../../store/notification";
// COMPONENTS
import ObserverComponent from "../ObserverComponent";
import Spinner from "../../../../../../common/Spinner";
import NotificationItem from "./components/NotificationItem";
import EmptyNotification from "./components/EmptyNotification";

const NotificationPopover = ({
  open,
  anchorEl,
  handleClose,
  setCurrentNotification,
}) => {
  const notifications = useSelector((state) => state.notification.items);
  const hasNextPage = useSelector(
    (state) => state.notification.info.hasNextPage
  );
  const nextPage = useSelector((state) => state.notification.info.nextPage);
  const currentPage = useSelector((state) => state.notification.info.page);
  const isLoading = useSelector((state) => state.notification.isLoading);
  const [intersecting, setIntersecting] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!hasNextPage || isLoading || !intersecting) return;
    dispatch(
      notificationStore.asyncActions.getNotifications({
        page: nextPage,
        limit: 10,
      })
    );
  }, [intersecting, isLoading, hasNextPage, nextPage]);

  const handleDeleteNotification = useCallback(async (e) => {
    e.stopPropagation();

    const id = e.currentTarget.getAttribute("data-id");

    if (!id) return;

    await dispatch(notificationStore.asyncActions.deleteNotifications({ id }));
    await dispatch(
      notificationStore.asyncActions.getNotifications({
        page: currentPage,
        limit: 10,
      })
    );
  }, []);

  const handleClickItem = useCallback(
    async (e) => {
      const id = e.currentTarget.getAttribute("data-id");

      const currentItem = notifications.find((item) => item._id === id);

      if (!currentItem) return;

      if (currentItem.status === notificationStatuses.unread)
        dispatch(notificationStore.asyncActions.markAsRead({ id }));

      handleClose();
      setCurrentNotification(currentItem);
    },
    [notifications]
  );

  return (
    <Popover
      id="notifications_popover"
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      className="notifications-popover"
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <List className="notifications">
        {!notifications.length && <EmptyNotification onClose={handleClose} />}
        {notifications.map((item) => (
          <NotificationItem
            key={item._id}
            item={item}
            onClickItem={handleClickItem}
            onDeleteNotification={handleDeleteNotification}
          />
        ))}
        <ObserverComponent setIntersecting={setIntersecting} />
        {isLoading && notifications.length > 10 && (
          <Box className="notifications__spinner">
            <Spinner />
          </Box>
        )}
      </List>
    </Popover>
  );
};

export default memo(NotificationPopover);
