import trap from "../../helpers/fp/decorators/trap";
import axiosInstance from "../axiosInstance";

const getUserActivities = trap(() => axiosInstance.get(`/users/current/activities`));

const userActivityApi = Object.freeze({
  getUserActivities,
});

export default userActivityApi;
