import axiosInstance from "./axiosInstance";
import concatPaths   from "../helpers/fp/url/concatPaths";

const DNS_PATH = "dns";
const TXT_PATH = "txt";

const dnsApi = Object.freeze({
  resolveCname : (domainName) => axiosInstance.get(concatPaths([DNS_PATH, domainName])),
  validateTxt  : ({ domainName, txtRecord }) => axiosInstance.post(concatPaths([DNS_PATH, TXT_PATH]), { domainName, txtRecord }),
});

export default dnsApi;
