// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";

const createProject = (data) => axiosInstance.post(`/projects`, data)
const getProjects = ({ teamId }) => getTrap(`/teams/${teamId}/projects`)

const updateProject = ({ id, data }) => axiosInstance.put(`/projects/${id}`, data)

const getProjectResources = ({ teamId, projectId }) => getTrap(`/teams/${teamId}/projects/${projectId}/resources`)

const deleteProject = ({ teamId, projectId }) => axiosInstance.delete(`/teams/${teamId}/projects/${projectId}`)

const projectService = Object.freeze({
  createProject,
  getProjects,
  updateProject,
  getProjectResources,
  deleteProject
});

export default projectService;
