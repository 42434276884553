import trap from "../../helpers/fp/decorators/trap";
import axiosInstance from "../axiosInstance";

const getUserData = trap(() => axiosInstance.get(`/users/current`));
const updateUserData = (data) => axiosInstance.put(`/users/current`, data);
const verifyUserPayment = (data) =>
  axiosInstance.post(`/users/current/verify-payment`, data);
const createAuthPaymentIntent = (data) =>
  axiosInstance.post(`/users/current/auth-payments`, data);
const deactivateAccount = (data) =>
  axiosInstance.put(`/users/current/deactivate`, data);

const userApi = Object.freeze({
  getUserData,
  updateUserData,
  verifyUserPayment,
  deactivateAccount,
  createAuthPaymentIntent,
});

export default userApi;
