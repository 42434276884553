import useAuthUser    from "../user/useAuthUser";
import useCurrentTeam from "./useCurrentTeam";

import isOwnerByUser from "../../helpers/fp/models/team/isOwnerByUser";

const useIsCurrentOwner = () => {
  const user = useAuthUser();
  const currentTeam = useCurrentTeam();
  return isOwnerByUser(user)(currentTeam);
};

export default useIsCurrentOwner;
