const getId = (value) => value
  ? "_id" in Object(value)
    ? value._id
    : typeof value === "string"
      ? value
      : ""
  : ""
;

export default getId;
