import axiosInstance from "../axiosInstance";

const PATH = "/tickets";
const getTicketById = ticketId => axiosInstance.get(PATH + "/" + ticketId);

const ticketApi = Object.freeze({
  createTicket: async ({ subject, content, files }) => {
    const resp = await axiosInstance.post(PATH, { subject, content, files });
    const ticket = await getTicketById(resp.data)
    return ticket.data;
},

  createNest: ({ ticketId, content, files }) => axiosInstance.post(PATH + "/" + ticketId, {
    content,
    files,
  }).then((result) => {
    result.data = {
      parentId: ticketId,
      content,
      createdAt: Date.now(),
    };
    return result;
  }),

  createEmail     : async ({ from, content, subject, ticketId, files  }) => {
    await axiosInstance.post(PATH + "/email/" + ticketId, { from, text: content, subject, ticketId, files });
    const ticket = await getTicketById(ticketId);
    return ticket.data.emails;
  },
  
  getTickets      : () => axiosInstance.get(PATH),

  getTicketById,

  closeTicketById : ticketId => axiosInstance.delete(PATH + "/" + ticketId).then((result) => {
    result.data = ticketId;
    return result;
  }),
});

export default ticketApi;
