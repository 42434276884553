import React from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import './style.scss';
import match from "../../../helpers/fp/logic/match";

const buttonStyledClass = match({
  'transparent' : 'transparent-button',
  'gray'        : 'gray-button',
});

/**
  * @param {object} props
  * @param {string} [props.className]
  * @param {'gray' | 'transparent'} [props.buttonStyle]
  * @param {boolean} [props.disabled]
  * @param {ReactNode} [props.children]
  * @param {Parameters<typeof LoadingButton>} [props.props]
  */
const ButtonComponent = ({
  className,
  children,
  buttonStyle,
  disabled,
  loading,
  ...props
}) => {
  return (
    <LoadingButton
      className={["button-component", className, buttonStyledClass(buttonStyle), disabled ? 'disabled' : ''].filter(Boolean).join(' ')}
      disabled={Boolean(disabled || loading)}
      loading={Boolean(loading)}
      {...props}
    >
      {children}
    </LoadingButton>
  );
};

export default ButtonComponent;
