// CORE
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
// MUI
import {
  AppBar,
  Badge,
  Box,
  Divider,
  IconButton,
  Toolbar,
} from "@mui/material";

// STYLES
import "./dashboardHeader.scss";

// ICONS
import { IconAlarm } from "../../../../../assets/icons";

// COMPONENTS
import AccountInfo                from "../../../../shared/AccountInfo/AccountInfo";
import AutocompleteSearchResource from "./components/AutocompleteSearchResource/AutocompleteSearchResource";
import MenuCreateProducts         from "./components/MenuCreateProducts/MenuCreateProducts";
import NotificationPopover        from "./components/NotificationPopover";

const DashboardHeader = ({
  setNextTeam,
  setCurrentNotification,
  className,
  sx,
  ...props
}) => {
  const totalUnreadDocs = useSelector(
    (state) => state.notification.info.totalUnreadDocs
  );
  const notifications = useSelector((state) => state.notification.items);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [notifications]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <AppBar
      className={"dashboard-header" + (className ? " " + className : "")}
      sx={{
        display: "flex",
        width: "100%",
        ...sx,
      }}
      {...props}
    >
      <Toolbar
        className="toolbar"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <AutocompleteSearchResource />
        <Box
          display="flex"
          justifyContent="flex-end"
          padding="0 30px 0 8px"
          alignItems="center"
        >
          <MenuCreateProducts />
          <IconButton
            onClick={handleClick}
            sx={{ margin: "0 16px" }}
            size="small"
          >
            <Badge badgeContent={totalUnreadDocs} color="error">
              <IconAlarm />
            </Badge>
          </IconButton>
          <NotificationPopover
            setCurrentNotification={setCurrentNotification}
            open={!!anchorEl}
            anchorEl={anchorEl}
            handleClose={handleClose}
          />
          <Divider
            orientation="vertical"
            sx={{
              marginInline: "auto",
            }}
            variant="middle"
            flexItem
          />
          <AccountInfo setNextTeam={setNextTeam} />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default DashboardHeader;
