// CORE
import { createAsyncThunk } from "@reduxjs/toolkit";
// SERVICES
import billingAlertService from "../../services/billingAlert";

export default {
  createBillingAlert: createAsyncThunk(
    "post/billing-alert",
    billingAlertService.createBillingAlert
  ),
  getBillingAlert: createAsyncThunk(
    "get/billing-alert",
    billingAlertService.getBillingAlertByTeam
  ),
  updateBillingAlert: createAsyncThunk(
    "put/billing-alert",
    billingAlertService.updateBillingAlertById
  ),
  deleteBillingAlert: createAsyncThunk(
    "delete/billing-alert",
    billingAlertService.deleteBillingAlertById
  ),
};
