// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from '../../helpers/fp/array/empty';
import updateMany from '../../helpers/store/updateMany';
import updateOne from '../../helpers/store/updateOne';

const databaseSlice = createSlice({
  name : 'databases',
  initialState : {
    items     : empty(),
    current   : null,
    isLoading : true
  },
  reducers : {
    setCurrentDatabase(state, { payload }) {
      return ({
        ...state,
        current: payload
      });
    },
    updateDatabase(state, { payload }) {
      return ({
        ...state,
        items: state.items.map((database) => (database)._id === payload._id ? { ...(database),...payload } : (database))
      });
    },
    deleteDatabase(state, { payload }) {
      const index = state.items.findIndex((database) => database._id === payload._id);
      if (index === -1) return state;
      return ({
        ...state,
        items: state.items.slice(0, index).concat(state.items.slice(index + 1))
      });
    }
  },
  extraReducers : builder => builder
    .addCase(asyncActions.getDatabases.fulfilled, (state, { payload }) => ({
      ...state,
      items     : updateMany(state.items, payload.data),
      isLoading : false
    }))
    .addCase(asyncActions.getDatabases.pending, (state) => ({
      ...state,
      isLoading : true,
    }))
    .addCase(asyncActions.getDatabases.rejected, (state) => ({
      ...state,
      isLoading : false
    }))
    .addCase(asyncActions.getByTeam.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateMany(state.items, payload.data),
    }))
    .addCase(asyncActions.getByProject.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateMany(state.items, payload.data),
    }))
    .addCase(asyncActions.cancelDatabase.fulfilled, (state, { payload }) => ({
      ...state,
      items: updateOne(state.items, payload.data),
    }))
});

const databaseStore = Object.freeze({
  ...databaseSlice,
  asyncActions,
});

export default databaseStore;
