// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

import eq    from '../../helpers/fp/array/internal/eq';
import empty from '../../helpers/fp/array/empty';


const projectSlice = createSlice({
  name: 'project',
  initialState: {
    items      : empty(),
    resources  : empty(),
    current    : null,
    newProject : null,
    isLoading  : false
  },
  reducers: {
    clearState(state) {
      state.items = empty();
      state.current = null;

      return state;
    },
    setCurrentProject(state, { payload }) {
      state.current = payload;

      return state;
    },
    setDefaultProject(state, { payload }) {
      if (state.current) return state;

      state.current = payload;

      return state;
    },
    clearNewProject(state) {
      state.newProject = null;

      return state;
    }
  },
  extraReducers: builder => {
    builder
      .addCase(asyncActions.getProjects.fulfilled, (state, { payload }) => {
        if (eq(state.items, payload.data)) {
          return ({
            ...state,
            isLoading: false
          });
        }

        return ({
          ...state,
          items: payload.data,
          isLoading: false
        });
      })
      .addCase(asyncActions.getProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getProjects.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.updateProject.fulfilled, (state, { payload }) => {
        state.current = payload.data;
        state.items = state.items.map(item => {
          if (payload.data._id !== item._id) return payload.data.isDefault ? { ...item, isDefault: false } : item

          return payload.data;
        })
        state.isLoading = false;
      })
      .addCase(asyncActions.createProject.fulfilled, (state, { payload }) => {
        state.newProject = payload.data;
      })
      .addCase(asyncActions.getProjectResources.fulfilled, (state, { payload }) => {
        if (eq(state.resources, payload.data)) return state;
        return ({
          ...state,
          resources: payload.data
        });
      })
  },
});

const projectStore = Object.freeze({
  ...projectSlice,
  asyncActions,
});

export default projectStore;
