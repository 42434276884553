// CORE
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// MUI
import Box        from "@mui/material/Box"
import Typography from "@mui/material/Typography"

// STYLES
import './styles.scss';

// STORE
import notificationStore from "../../../store/notification";
import inviteApi         from "../../../api/invite";
import teamStore         from "../../../store/team";

// CONSTANTS
import { notificationStatuses } from "../../../constants/notification";

// COMPONENTS
import ModalWindow from "../ModalWindow";
import Button      from "../../common/Button";

const showInviteBtn = (status) => {
  switch (status) {
    case notificationStatuses.rejected :
    case notificationStatuses.accepted : return false;
    default                            : return true;
  }
}

const InviteModal = ({ onClose, setNextTeam, notification }) => {
  const [loading, setLoading] = useState(false);
  const dispatch              = useDispatch();

  const handleAcceptInvite = useCallback(async () => {
    setLoading(true)
    try {
      await inviteApi.acceptInviteByNotification({ id: notification.params.invite._id });
      const { data } = await dispatch(teamStore.asyncActions.getTeams()).unwrap();
      const nextTeam = data.find(team => team._id === notification.params.team._id);

      if (nextTeam) setNextTeam(nextTeam);

      dispatch(notificationStore.asyncActions.getNotifications({ page: 1, limit: 10 }));
    } catch (e) {
      toast.error("Failed to join the team. Try again later")
    }
    setLoading(false)
    onClose()
  }, [notification])


  const title = `Join the Team of ${notification.params.team.name}`;

  return <ModalWindow {...{ open: true, setOpen: onClose, title }}>
    <Box className="invite-modal">
      <Typography component="p" className="invite-modal__description">
        You've been invited by {notification.params.sender.name} "{notification.params.sender.email}" to join
        the {notification.params.team.name} team on the Enclaive platform
      </Typography>
      {showInviteBtn(notification.status) && <Button loading={loading} onClick={handleAcceptInvite} className="invite-modal__btn">
        Accept Invitation
      </Button>}
    </Box>
  </ModalWindow>
};

export default InviteModal;
