
import useCurrentTeam from "./useCurrentTeam";

import getId from "../../helpers/fp/models/getId";
import ap    from "../../helpers/fp/monads/ap";
import lift  from "../../helpers/fp/monads/lift";
import maybe from "../../helpers/fp/monads/maybe";
import pipe from "../../helpers/fp/composition/pipe";

const useCurrentTeamId = pipe([
  useCurrentTeam,
  maybe,
  ap(getId),
  lift
]);

export default useCurrentTeamId;
