// CORE
import { createSlice } from '@reduxjs/toolkit';

import findIndex from "lodash/fp/findIndex"

import billingSettingsAsyncActions from './asyncActions';

import empty from '../../helpers/fp/array/empty';
import eq    from '../../helpers/fp/object/internal/eq';

const findIndexById = (id) => findIndex((value) => value._id === id);

const setBillingSettingsById = (state, { meta, payload }) => {
  const { data } = payload;
  const billingSettingsId = meta?.arg || data._id;
  const index = findIndexById(billingSettingsId)(state);
  if (index === -1) return state.concat(data);
  const billingSetting = state[index];
  data.teams = billingSetting.teams;
  if (eq(billingSetting, data)) return state;
  return state.slice(0, index).concat(data, state.slice(index + 1))
};

const billingSettingsSlice = createSlice({
    name : 'billingSettings',
    initialState : empty(),
    reducers : {
      clear : empty,
      set   : setBillingSettingsById,
    },
    extraReducers : builder => {
      builder
        .addCase(billingSettingsAsyncActions.getBillingSettingsByTeam.fulfilled, setBillingSettingsById)
        .addCase(billingSettingsAsyncActions.updateBillingSettingsByTeam.fulfilled, setBillingSettingsById)
        .addCase(billingSettingsAsyncActions.updateBillingSettings, setBillingSettingsById)
        .addCase(billingSettingsAsyncActions.getBillingSettingsById.fulfilled, setBillingSettingsById)
        .addCase(billingSettingsAsyncActions.getBillingSettings.fulfilled, (state, { payload }) => payload.data)
    },
});

const billingSettingsStore = Object.freeze({
  ...billingSettingsSlice,
  asyncActions: billingSettingsAsyncActions,
});

export default billingSettingsStore;
