// CORE
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
// STORE
import serverModeStore from "../../store/serverMode";
// CONSTANTS
import { serverModeStatuses, serverModeTypes } from "../../constants/serverMode";

const useServerMode = () => {
  const modes = useSelector((state) => state.serverMode.items);
  const isModeError = useSelector((state) => state.serverMode.isError);
  const isModeLoading = useSelector((state) => state.serverMode.isLoading);
  const dispatch = useDispatch();

  const maintenanceMode = useMemo(
    () => modes.find((mode) => mode.type === serverModeTypes.maintenance),
    [modes]
  );
  const waitingListMode = useMemo(
    () => modes.find((mode) => mode.type === serverModeTypes.waitinglist),
    [modes]
  );

  useEffect(() => {
    dispatch(serverModeStore.asyncActions.getServerModes());
  }, []);

  return useMemo(
    () => ({
      isShowLoader: (isModeLoading || !maintenanceMode || !waitingListMode) && !isModeError,
      isShowMaintenance: maintenanceMode && maintenanceMode.status === serverModeStatuses.off || isModeError,
      isShowWaitingList: waitingListMode && waitingListMode.status === serverModeStatuses.off
    }),
    [maintenanceMode, waitingListMode, isModeError, isModeLoading]
  );
};

export default useServerMode;
