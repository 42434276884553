import T       from "lodash/fp/T"

const createUseSkip = (key) => {
  const getSkip    = ()       => localStorage.getItem(key);
  const skipById   = (teamId) => localStorage.setItem(key, teamId);
  const isSkipById = (teamId) => getSkip() === teamId;

  return (teamId) => {
    if (!teamId) return ({
      isSkip : T,
      onSkip : Boolean,
    });

    return ({
      isSkip : () => isSkipById(teamId),
      onSkip : () => skipById(teamId),
    });
  };
};

export default createUseSkip;
