import { createAsyncThunk } from "@reduxjs/toolkit";

import ticketApi from "../../api/ticket";

const asyncTicketActions = Object.freeze({
  createTicket    : createAsyncThunk("ticket/create", ticketApi.createTicket),
  createNestTicket: createAsyncThunk("ticket/createNest", ticketApi.createNest),
  createEmailTicket: createAsyncThunk("ticket/createEmail", ticketApi.createEmail),
  getTickets      : createAsyncThunk("ticket/get", ticketApi.getTickets),
  getTicketById   : createAsyncThunk("ticket/getById", ticketApi.getTicketById),
  closeTicketById : createAsyncThunk("ticket/close", ticketApi.closeTicketById),
});

export default asyncTicketActions;
