import axiosInstance from "../axiosInstance";

import trap     from "../../helpers/fp/decorators/trap";
import propData from "../../helpers/fp/models/propData";

const promoApi = Object.freeze({
  hasActivePromoCode : trap((code) => axiosInstance.get("/promo/" + code)),
  applyPromoCode     : trap((code) => axiosInstance.post("/promo/" + code).then(propData)),
  list               : trap(() => axiosInstance.get("/promo").then(propData)),
  delete             : trap((code) => axiosInstance.delete("/promo/" + code)),

  activate           : trap((code) => axiosInstance.post("/promo/" + code + "/active")),
  deactivate         : trap((code) => axiosInstance.delete("/promo/" + code + "/active")),
});

export default promoApi;
