import React from "react";
import { Divider } from "@mui/material";

// ICONS
import {
  IconCode,
  IconDatabase,
  IconAddDroplet,
  IconKubernetes,
  IconBraces,
} from "../../../../../../../../assets/icons";
import {
  appNewRoute,
  databaseNewRoute,
  KUBERNETES_NEW_ROUTE,
  virtualMachineNewRoute,
} from "../../../../../../../../constants/routes/routes";

export const productsList = [
  {
    element    : <IconAddDroplet />,
    title      : 'Buckypaper',
    subTitle   : 'Create virtual machines',
    to         : virtualMachineNewRoute,
    isDisabled : false,
  },
  {
    element    : <IconKubernetes />,
    title      : 'Dyneemes',
    subTitle   : 'Create Kubernetes clusters',
    to         : KUBERNETES_NEW_ROUTE,
    isDisabled : false,
  },
  {
    element    : <IconBraces/>,
    title      : 'Morphism',
    subTitle   : 'Create cloud functions',
    to         : '/',
    isDisabled : true,
  },
  {
    element    : <Divider sx={{ margin : '5px 0' }}/>,
    title      : 'Divider',
    subTitle   : 'First divider',
    to         : "",
    isDisabled : false,
  },
  {
    element    : <IconDatabase/>,
    title      : 'Databases',
    subTitle   : 'Create always encrypted databases',
    to         : databaseNewRoute,
    isDisabled : false,
  },
  {
    element    : <IconCode/>,
    title      : 'Apps',
    subTitle   : 'Try ready-made SaaS solutions',
    to         : appNewRoute,
    isDisabled : false,
  },
];
