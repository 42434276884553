import { useEffect } from "react";

import filter from "lodash/fp/filter"
import prop   from "lodash/fp/prop"

// hooks
import usePaymentMethods from "../usePaymentMethods";
import useFetchPaymentMethodsByBillingSettingsId from "../useFetchPaymentMethodsByBillingSettingsId";

import pipe from "../../../helpers/fp/composition/pipe";
import is   from "../../../helpers/fp/logic/is";

const getBillingSettingsId      = prop("billingSettingsId");
const existBillingSettingId     = (billingSettingsId) => pipe([getBillingSettingsId, is(billingSettingsId)])
const filterByBillingSettingsId = (billingSettingsId) => filter(existBillingSettingId(billingSettingsId));

/**
  * @typedef {import("../../../types/PaymentMethod").default} PaymentMethod
  */

/**
  * @param {string} [billingSettingsId]
  * @returns {readonly PaymentMethod[] | []}
  */
const usePaymentMethodsByBillingSettingsId = (billingSettingsId) => {
  const paymentMethods                         = usePaymentMethods(filterByBillingSettingsId(billingSettingsId));
  const fetchPaymentMethodsByBillingSettingsId = useFetchPaymentMethodsByBillingSettingsId();

  useEffect(() => {
    if (billingSettingsId) fetchPaymentMethodsByBillingSettingsId(billingSettingsId);
  }, [billingSettingsId]);

  return paymentMethods;
};

export default usePaymentMethodsByBillingSettingsId;
