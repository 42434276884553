// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import virtualMachineService from '../../services/virtualMachine';

const virtualMachineAsyncActions = Object.freeze({
  createVirtualMachines    : createAsyncThunk('post/virtualMachines',      virtualMachineService.createVirtualMachines),
  getVirtualMachines       : createAsyncThunk('get/virtualMachines',       virtualMachineService.getVirtualMachines),
  getVirtualMachine        : createAsyncThunk('get/getVirtualMachine',     virtualMachineService.getVirtualMachine),
  stopVirtualMachine       : createAsyncThunk('put/stopVirtualMachine',    virtualMachineService.stopVirtualMachine),
  startVirtualMachine      : createAsyncThunk('put/startVirtualMachine',   virtualMachineService.startVirtualMachine),
  deleteVirtualMachine     : createAsyncThunk('delete/virtualMachines',    virtualMachineService.deleteVirtualMachine),
  cancelVirtualMachine     : createAsyncThunk('put/cancelVirtualMachine',  virtualMachineService.cancelVirtualMachine),

  getByTeam    : createAsyncThunk('get/virtualMachinesByTeam', virtualMachineService.getByTeam),
  getByProject : createAsyncThunk('get/virtualMachinesByProject', virtualMachineService.getByProject),
});

export default virtualMachineAsyncActions;
