// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
import promoApi from '../../api/promo';

// API
const promoAsyncActions = Object.freeze({
  applyPromoCode : createAsyncThunk('promo/apply', promoApi.applyPromoCode),
  list           : createAsyncThunk('promo/list',  promoApi.list),
  delete         : createAsyncThunk('promo/delete', promoApi.delete),

  activate       : createAsyncThunk('promo/activate', promoApi.activate),
  deactivate     : createAsyncThunk('promo/deactivate', promoApi.deactivate),
});

export default promoAsyncActions;
