// SERVICES
import teamService from "../../services/team";
// HELPERS
import vaultTokenJWT from "../../helpers/vaultTokenJWT";
import vaultToken from "../../helpers/vaultToken";

const switchTeam = async ({ teamId }) => {
  const { data } = await teamService.setCurrentTeam({ teamId });

  vaultTokenJWT.value = data.vaultToken;
  vaultToken.pop();
};

export default {
  switchTeam
}
