// CORE
import React from "react";
// MUI
import { Box, ListItem, Typography } from "@mui/material";

const EmptyNotification = ({ onClose }) => {
  return (
    <ListItem onClick={onClose} className="notifications__item disabled">
      <Box className="notifications__content">
        <Typography
          variant="subtitle2"
          component="p"
          className="notifications__message"
        >
          You are up to date.
        </Typography>
      </Box>
    </ListItem>
  );
};

export default EmptyNotification;
