import isPast              from "../../../date/isPast";
import isAnySuspendedOwner from "../isAnySuspendedOwner";
import teamIsDeactivated   from "../isDeactivated";

const teamIsSuspended = (team) => (team && (
  (isPast(team.suspendedAt)) ||
  (teamIsDeactivated(team))  ||
  (isAnySuspendedOwner(team))
));

export default teamIsSuspended;
