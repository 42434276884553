import { createContext } from "react";

/**
  * @typedef {object} Def
  * @prop {boolean}  Def.isOpenCompleteBillingWindow
  * @prop {function} Def.setIsOpenCompleteBillingWindow
  * @prop {(call: (access: boolean) => any) => boolean} Def.verifyBilling
  * @prop {function} Def.checkIsNotPaymentVerified
  */

/** @type {import("react").Context<Def>} */
export default createContext();
