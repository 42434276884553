var values = new Map;
globalThis.requestIdleCallback ||= (call) => setTimeout || setTimeout(call, 0) || call();
globalThis.localStorage ||= ({
  getItem    : (key) => values.get(key),
  setItem    : (key, value) => values.set(key, value),
  removeItem : (key) => values.delete(key),
});

class DataStorage {
  constructor ({
    storage = localStorage || ({
      getItem    : (key) => values.get(key),
      setItem    : (key, value) => values.set(key, value),
      removeItem : (key) => values.delete(key),
    }),
    name,
  }) {
    this.storage = storage;
    this.name    = name;

    /** @private */
    this._value  = storage.getItem(this.name) || "";
  }

  get value () {
    return this._value;
  }

  set value (value) {
    this._value = value;
    requestIdleCallback(() => this.storage.setItem(this.name, this._value));
  }

  pop () {
    this._value = "";
    requestIdleCallback(() => this.storage.removeItem(this.name));
  }
}

export default DataStorage;
