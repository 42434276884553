// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import invoiceService from '../../services/invoice';


export default ({
  getInvoices: createAsyncThunk('get/getInvoices', invoiceService.getInvoices),
  getLastInvoice: createAsyncThunk('get/getLastInvoice', invoiceService.getLastInvoice),
  retryInvoice: createAsyncThunk('post/retryInvoice', invoiceService.retryInvoice)
});
