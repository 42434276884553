// CORE
import axiosInstance from "../axiosInstance";
// HELPERS
import trap from "../../helpers/fp/decorators/trap";
import vaultTokenJWT from "../../helpers/vaultTokenJWT";
import accessToken from "../../helpers/accessToken";
import vaultToken from "../../helpers/vaultToken";

const queryParam = (name) => (value) => value ? `${name}=${value}` : "";
const capchaQuery = queryParam("capcha");
const referrerQuery = queryParam("referrer");
const queryValues = (values) =>
  values && values.length > 0 ? values.filter(Boolean) : [];
const query = (values) =>
  queryValues(values).length > 0 ? "?" + values.join("&") : "";

const register = ({ name, email, password, capcha, referrer }) =>
  axiosInstance.post(
    "/register" + query([capchaQuery(capcha), referrerQuery(referrer)]),
    { name, email, password }
  );

const login = ({ email, password }) =>
  axiosInstance.post("/login", { email, password });

const authToken = (token) => axiosInstance.get(`/auth-token/${token}`);
const reactivate = () => axiosInstance.put(`/users/current/reactivate`);

const resendVerificationEmail = ({ capcha }) =>
  axiosInstance.get(
    "/resend-email-verification" + query([capchaQuery(capcha)])
  );
const forgotPassword = ({ email }) =>
  axiosInstance.post("/forgot-password", { email });
const resetPassword = ({ token, newPassword }) =>
  axiosInstance.post(`/reset-password`, { token, newPassword });
const confirmEmail = ({ token }) => axiosInstance.get(`/verify-email/${token}`);

const existEmail = trap((email) => axiosInstance.get(`/exist-email/${email}`));

const refresh = trap(() => axiosInstance.get("/refresh"));

const refreshJWT = async () => {
  const { data } = await refresh();

  vaultTokenJWT.value = data.vaultToken;
  accessToken.value = data.accessToken;
  vaultToken.pop();

  return data;
};

const authApi = Object.freeze({
  axiosInstance,
  authToken,
  login,
  refresh,
  register,
  forgotPassword,
  resetPassword,
  confirmEmail,
  resendVerificationEmail,
  existEmail,
  reactivate,
  refreshJWT
});

export default authApi;
