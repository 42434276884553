// AXIOS
import axiosInstance from "../../api/axiosInstance";

const createBillingAlert = (data) =>
  axiosInstance.post(`/billing-alerts`, data);

const getBillingAlertByTeam = ({ teamId }) =>
  axiosInstance.get(`/teams/${teamId}/billing-alerts`);

const updateBillingAlertById = ({ id, update }) =>
  axiosInstance.put(`/billing-alerts/${id}`, update);

const deleteBillingAlertById = ({ id }) =>
  axiosInstance.delete(`/billing-alerts/${id}`);

const billingAlertService = Object.freeze({
  createBillingAlert,
  getBillingAlertByTeam,
  updateBillingAlertById,
  deleteBillingAlertById,
});

export default billingAlertService;
