// AXIOS
import axiosInstance from "../../api/axiosInstance";

const getInvoices = ({ page, teamId }) => axiosInstance.get(`/teams/${teamId}/billing-history?page=${page}`);
const getLastInvoice = ({ teamId }) => axiosInstance.get(`/teams/${teamId}/last-invoice`);

const retryInvoice = ({ id, data }) => axiosInstance.post(`/invoices/${id}`, data);

export default {
  getInvoices,
  getLastInvoice,
  retryInvoice
}
