import { createAsyncThunk } from "@reduxjs/toolkit";
import byosApi from "../../api/byos";

const asyncByosActions = Object.freeze({
  list        : createAsyncThunk("byos/list",        byosApi.list),
  subscribe   : createAsyncThunk("byos/subscribe",   byosApi.subscribe),
  unsubscribe : createAsyncThunk("byos/unsubscribe", byosApi.unsubscribe),
});

export default asyncByosActions;
