// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';

// helpers
import updateMany from '../../helpers/store/updateMany';
import updateOne  from '../../helpers/store/updateOne';
import empty      from '../../helpers/fp/array/empty';

const k8sSlice = createSlice({
    name : 'k8s',
    initialState : {
      items     : empty(),
      versions  : empty(),
      isLoading : false,
    },
    reducers : {
      clearVersions: (state) => ({
        ...state,
        versions: empty(),
      }),
      updateCluster(state, { payload }) {
        const clusterId = payload._id;
        const index = state.items.findIndex(cluster => cluster._id === clusterId);
        return index > -1
          ? ({
            ...state,
            items: state.items.slice(0, index).concat({ ...state.items[index], ...payload })
          })
          : state
        ;
      },
      updateMasterNodeNode(state, { payload }) {
        const clusterId = payload.cluster._id;
        const index = state.items.findIndex((cluster) => cluster._id === clusterId);
        return (index > -1)
          ? ({
            ...state,
            items: state
              .items
              .slice(0, index)
              .concat({
                ...state.items[index],
                masterNode: ({
                  ...state.items[index]?.masterNode,
                  ...payload,
                }),
              })
          }) : state
        ;
      },
      updateWorkerNodeNode(state, { payload }) {
        state.items = state.items.map(cluster => {
          if (payload.pool.cluster._id !== cluster._id) return cluster;

          const pools = cluster.pools.map(pool => ({
            ...pool, nodes: pool.nodes.map(node => {
              if (node._id !== payload._id) return node;

              return { ...node,...payload }
            })
          }))

          return { ...cluster, pools }
        });

        return state;
      },
      deleteCluster(state, { payload }) {
        state.items = state.items.filter(cluster => cluster._id !== payload._id);

        return state;
      },
      deletePool(state, { payload }) {
        state.items = state.items.map(cluster => payload.cluster._id === cluster._id ? ({
          ...cluster,
          pools: cluster.pools.filter(pool => pool._id !== payload._id)
        }) : cluster);

        return state;
      },
      createdPool(state, { payload }) {
        state.items = state.items.map(cluster => payload.cluster._id === cluster._id ? ({
          ...cluster,
          pools: [...cluster.pools, payload]
        }) : cluster);

        return state;
      },
    },
    extraReducers : builder => builder
      .addCase(asyncActions.getVersions.fulfilled, (state, { payload }) => {
        state.versions = payload.data;
      })
      .addCase(asyncActions.getClusters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getClusters.fulfilled, (state, { payload }) => ({
        ...state,
        isLoading : false,
        items     : updateMany(state.items, payload.data),
      }))
      .addCase(asyncActions.getClusters.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncActions.getByTeam.fulfilled, (state, { payload }) => ({
        ...state,
        items: updateMany(state.items, payload.data)
      }))
      .addCase(asyncActions.getByProject.fulfilled, (state, { payload }) => ({
        ...state,
        items: updateMany(state.items, payload.data)
      }))
      .addCase(asyncActions.createCluster.fulfilled, (state, { payload }) => ({
        ...state,
        items: updateOne(state.items, payload.data)
      }))
});

const k8sStore = Object.freeze({
  ...k8sSlice,
  asyncActions,
});

export default k8sStore;
