import React from "react";

import "./style.scss";


const PageLoader = () => (
  <div className="page-loader-component">
    <span className="loader-circle" />
  </div>
);

export default PageLoader;
