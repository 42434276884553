// AXIOS
import axiosInstance, { getTrap } from "../../api/axiosInstance";
import { BILLING_SETTINGS_PATH } from "../../api/billingSettings";

const get = getTrap || axiosInstance.get;
const TEAMS_PATH = "/teams";
const teamPath   = (teamId) => TEAMS_PATH + "/" + teamId;

const getTeams              = () => get(TEAMS_PATH);
const createTeam            = (team) => axiosInstance.post(TEAMS_PATH, team).catch(error => Promise.reject(error.response.data || error));
const getTeam               = ({ teamId }) => get(teamPath(teamId));
const getTeamById           = (teamId) => get(teamPath(teamId));
const getTeamCosts          = ({ teamId }) => get(teamPath(teamId) + "/costs");
const updateTeam            = ({ teamId, updateData }) => axiosInstance.put(teamPath(teamId), updateData);
const deleteTeam            = ({ teamId }) => axiosInstance.delete(teamPath(teamId));
const inviteMembers         = ({ teamId, emails }) => axiosInstance.post(teamPath(teamId) + "/invite", { emails });
const createBillingSettings = ({ teamId, settingsData }) => axiosInstance.post(teamPath(teamId) + BILLING_SETTINGS_PATH, { settingsData });
const createTaxId           = ({ teamId, data }) => axiosInstance.post(teamPath(teamId) + "/tax_ids", data);
const deleteTaxId           = ({ teamId, id }) => axiosInstance.delete(teamPath(teamId) + "/tax_ids/" + id);
const getTeamResources      = ({ teamId }) => get(teamPath(teamId) + "/resources")
const moveResources         = ({ teamId,data }) => axiosInstance.post(teamPath(teamId) + "/move-resources",data)
const getInviteList         = ({ teamId }) => get(teamPath(teamId) + "/invite");
const cancelInvite          = ({ teamId, inviteId }) => axiosInstance.delete(teamPath(teamId) + "/invite/" + inviteId);
const leaveTeam             = ({ teamId }) => axiosInstance.delete(teamPath(teamId));
const removeFromTeam        = ({ teamId, userId }) => axiosInstance.delete(teamPath(teamId) +"/members/" +userId);

const setPaymentMethod     = ({
  teamId,
  paymentMethodId,
  returnUrl,
}) => axiosInstance.post(`/teams/${teamId}/payment_method/${paymentMethodId}`, { returnUrl });

const verifyPayment = ({
  teamId,
  paymentIntent,
  clientSecret,
}) => axiosInstance.post(`/teams/${teamId}/payment_method`, { paymentIntent, clientSecret });

const updateBillingSettings = ({
  teamId,
  ...settingsData
}) => axiosInstance.post(`/teams/${teamId}/billing_settings`, settingsData);

const getBillingSettings = (teamId) => get(`/teams/${teamId}/billing_settings`);

const getCurrentTeam = () => get("/teams/current");
const setCurrentTeam = ({ teamId }) => axiosInstance.post("/teams/" + teamId);
const setCurrentTeamById = (teamId) => setCurrentTeam({ teamId });

const teamService = Object.freeze({
  axiosInstance,
  getTeams,
  createTeam,
  getTeam,
  getTeamById,
  updateTeam,
  deleteTeam,
  inviteMembers,
  createBillingSettings,
  createTaxId,
  deleteTaxId,
  getTeamResources,
  moveResources,
  getTeamCosts,
  getInviteList,
  cancelInvite,
  leaveTeam,
  removeFromTeam,
  getCurrentTeam,
  setCurrentTeam,
  setCurrentTeamById,

  getBillingSettings,
  updateBillingSettings,
  setPaymentMethod,
  verifyPayment,
});

export default teamService;
