// CORE
import React from "react";
// ICONS
import {
    ProjectIcon1,
    ProjectIcon2,
    ProjectIcon3,
    ProjectIcon4,
    ProjectIcon5,
    ProjectIcon6,
    ProjectIcon7,
    ProjectIcon8,
    ProjectIcon9,
    ProjectIcon10,
    ProjectIcon11,
    ProjectIcon12
} from "../assets/icons";


export const getProjectIcon = (iconNumber) => {
    switch (Number(iconNumber)) {
        case 1:
            return <ProjectIcon1/>
        case 2:
            return <ProjectIcon2/>
        case 3:
            return <ProjectIcon3/>
        case 4:
            return <ProjectIcon4/>
        case 5:
            return <ProjectIcon5/>
        case 6:
            return <ProjectIcon6/>
        case 7:
            return <ProjectIcon7/>
        case 8:
            return <ProjectIcon8/>
        case 9:
            return <ProjectIcon9/>
        case 10:
            return <ProjectIcon10/>
        case 11:
            return <ProjectIcon11/>
        case 12:
            return <ProjectIcon12/>
        default:
            return <ProjectIcon1/>
    }
}