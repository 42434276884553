// CORE
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import findIndex from "lodash/fp/findIndex"

// ACTIONS
import asyncActions from './asyncActions';
import arrayEquals from "../../helpers/fp/array/internal/eq";
import eq from "../../helpers/fp/object/internal/eq";
import vaultTokenJWT from "../../helpers/vaultTokenJWT";
import empty from "../../helpers/fp/array/empty";
import isDraft from "../../helpers/fp/models/team/isDraft";
import vaultToken from "../../helpers/vaultToken";

const findIndexById = (id) => findIndex((value) => value._id === id);


/**
  * @param {array} items
  */
const getCurrentItems = (items, team) => {
  if (!team) return items;
  if (isDraft(team)) return items;
  const teamIndex = items.findIndex(item => item._id === team._id);
  if (teamIndex === -1) return items.concat(team);
  const oldTeam = items[teamIndex];
  if (eq(oldTeam, team)) return items;
  return items.slice(0, teamIndex).concat(team, items.slice(teamIndex + 1));
}

const getCurrentDraft = (draft, team) => {
  if (!draft) return draft;
  if (team.activatedAt === null) return draft;
  return draft._id === team._id ? null : draft
}

const teamSlice = createSlice({
  name: "team",
  initialState: {
    items     : empty(),
    invite    : [],
    draft     : null,
    current   : null,
    isLoading : false,
    resources : {
      dbs: [],
      vms: [],
      apps: [],
      clusters: [],
    },
    isNeedToCreateTeam: false,
  },
  reducers: {
    setCurrentTeam(state, { payload }) {
      state.current = payload;
      return state;
    },
    updateStatusTeamMember(state, { payload }) {
      const userId = payload._id;
      const { status } = payload;

      state.items = state.items.map((team) => {
        const teamUser = team.members.find(
          (member) => member.user._id === userId
        );

        if (!teamUser) return team;

        return {
          ...team,
          members: team.members.map((member) =>
            member.user._id === userId
              ? {
                  ...member,
                  user: { ...member.user, status },
                }
              : member
          ),
        };
      });

      if (!state.current) return state;

      state.current = {
        ...state.current,
        members: state.current.members.map((member) =>
          member.user._id === userId
            ? {
                ...member,
                user: { ...member.user, status },
              }
            : member
        ),
      };

      return state;
    },
    updateTeam(state, { payload }) {
      const updateTeamIndex = _.findIndex(state.items, { _id: payload._id });
      state.items.splice(updateTeamIndex, 1, payload);
      if (state.current._id === payload._id) {
        state.current = payload;
      }
      return state;
    },
    updateBillingSettingsByTeamId(state, { payload }) {
      const teams = state.items;

      const index = findIndexById(payload._id)(teams);
      if (index === -1) return ({
        ...state,
        items: teams.concat(payload),
      });

      const team = teams[index];
      const { billingSettings } = team;
      if (eq(billingSettings, payload.billingSettings)) return state;

      const newTeamData = ({ ...team, billingSettings: payload.billingSettings });

      return ({
        ...state,
        items: teams.slice(0, index).concat(newTeamData, teams.slice(index + 1)),
      });
    },
    clearTeam(state, { payload }) {
      state.items = state.items.filter((team) => team._id !== payload);
      return state;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(asyncActions.setCurrentTeamById.pending, (state, { meta }) => {
        const { teamId } = meta.arg;
        let team =
          state.items.find((team) => team._id === teamId) ||
          (state.draft && state.draft._id === teamId ? state.draft : null);

        if (!team) return state;

        if (!team.activatedAt) team = { ...team, activatedAt: new Date() };

        return {
          ...state,
          current: team,
          draft: getCurrentDraft(state.draft, team),
          items: getCurrentItems(state.items, team),
        };
      })
      .addCase(
        asyncActions.setCurrentTeamById.fulfilled,
        (state, { payload }) => {
          if (payload.data.vaultToken)
            vaultTokenJWT.value = payload.data.vaultToken;
            vaultToken.pop();

          const { team } = payload.data;
          if (team) {
            const items = getCurrentItems(state.items, team);
            if (eq(state.current, team) && items === state.items) return state;

            return {
              ...state,
              items,
              current: team,
              draft: getCurrentDraft(state.draft, team),
            };
          }

          return state;
        }
      )
      .addCase(asyncActions.getTeamById.fulfilled, (state, { payload }) => {
        const team = payload.data;

        if (isDraft(team))
          return {
            ...state,
            draft: team,
          };

        return {
          ...state,
          items: getCurrentItems(state.items, team),
          draft: getCurrentDraft(state.draft, team),
        };
      })
      .addCase(asyncActions.createTeam.fulfilled, (state, reducer) => {
        const team = reducer.payload.data;

        if (isDraft(team))
          return {
            ...state,
            draft: team,
          };

        return {
          ...state,
          items: getCurrentItems(state.items, team),
          draft: getCurrentDraft(state.draft, team),
        };
      })
      .addCase(asyncActions.updateTeam.fulfilled, (state, { payload }) => {
        const team = payload.data;

        if (isDraft(team))
          return {
            ...state,
            draft: team,
          };

        return {
          ...state,
          items: getCurrentItems(state.items, team),
          draft: getCurrentDraft(state.draft, team),
        };
      })
      .addCase(asyncActions.getTeams.fulfilled, (state, { payload }) => {
        if (arrayEquals(state.items, payload.data))
          return {
            ...state,
            isNeedToCreateTeam: !payload.data.length,
            isLoading: false,
          };

        return {
          ...state,
          items: payload.data,
          isNeedToCreateTeam: !payload.data.length,
          isLoading: false,
        };
      })
      .addCase(asyncActions.removeFromTeam.pending, (state, reducer) => {
        const { current: team } = state;
        if (
          team &&
          team._id === reducer.meta.arg.teamId &&
          team.members.some(
            (member) => member.user._id === reducer.meta.arg.userId
          )
        ) {
          return {
            ...state,
            current: {
              ...team,
              members: team.members.filter(
                (member) => member.user._id !== reducer.meta.arg.userId
              ),
            },
          };
        }

        return state;
      })
      .addCase(asyncActions.removeFromTeam.fulfilled, (state, { meta }) => ({
        ...state,
        current: state.current
          ? {
              ...state.current,
              members: state.current.members.filter(
                (member) => member.user._id !== meta.arg.userId
              ),
            }
          : null,
        items: state.items.map((item) => {
          if (item._id === meta.arg.teamId) {
            return {
              ...item,
              members: item.members.filter(
                (member) => member.user._id !== meta.arg.userId
              ),
            };
          }
          return item;
        }),
      }))
      .addCase(asyncActions.getTeams.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncActions.getTeams.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(
        asyncActions.cancelInvite.fulfilled,
        (state, { payload, meta }) => {
          if (payload.data)
            return {
              ...state,
              invite: state.invite.filter(
                (invite) => invite._id !== meta.arg.inviteId
              ),
            };

          return state;
        }
      )
      .addCase(
        asyncActions.getTeamResources.fulfilled,
        (state, { payload }) => {
          state.resources = payload.data;
        }
      )
      .addCase(asyncActions.moveResources.fulfilled, (state) => {
        state.resources = {
          dbs: [],
          vms: [],
          apps: [],
          clusters: [],
        };
      })
      .addCase(asyncActions.getInviteList.fulfilled, (state, { payload }) => ({
        ...state,
        invite: payload.data,
      }))
      .addCase(asyncActions.getCurrentTeam.pending, (state) => {
        if (state.items.length === 1 && !state.current)
          return {
            ...state,
            current: state.teams.at(0),
          };

        return state;
      })
      .addCase(asyncActions.getCurrentTeam.fulfilled, (state, { payload }) => {
        const team = payload.data;
        if (!team) return state;

        if (eq(state.current, team)) {
          const items = getCurrentItems(state.items, team);
          if (items === state.items) return state;

          return {
            ...state,
            current: team,
            draft: getCurrentDraft(state.draft, team),
            items,
          };
        }

        return {
          ...state,
          current: team,
          draft: getCurrentDraft(state.draft, team),
          items: getCurrentItems(state.items, team),
        };
      }),
});

const teamStore = Object.freeze({
  ...teamSlice,
  asyncActions,
});

export default teamStore;
