import {
  settingsRoute,
  billingRoute,
} from "../../../../../../../../constants/routes/routes";

export const listItems = [
  {
    title      : 'Settings',
    link       : settingsRoute,
    isDisabled : false
  },
  {
    title      : 'Billing',
    link       : billingRoute,
    isDisabled : false
  }
];
