import { memo, useCallback, useLayoutEffect, useRef } from "react";
import { Box } from "@mui/material";

const ObserverComponent = ({ setIntersecting }) => {
  const container = useRef(null);

  const callbackObserver = useCallback((entries) => {
    const [entry] = entries;
    setIntersecting(entry.isIntersecting)
  }, [setIntersecting])

  useLayoutEffect(() => {
    const observer = new IntersectionObserver(callbackObserver);
    if (container.current) observer.observe(container.current)

    return () => {
      if (container.current) observer.unobserve(container.current)
    }
  }, [])

  // eslint-disable-next-line react/react-in-jsx-scope
  return <Box ref={container}/>
}

export default memo(ObserverComponent);
