import { createSlice } from '@reduxjs/toolkit';

import T from "lodash/fp/T";
import F from "lodash/fp/F";

import asyncTicketActions from './asyncSupportActions';

import overProp   from '../../helpers/fp/object/overProp';

const isCreatingTicketOver = overProp("isCreatingTicketLens");
const creatingTicket       = isCreatingTicketOver(T);

const isLoadingTicketOver = overProp("isLoadingTickets")
const loadingTickets      = isLoadingTicketOver(T);
const loadedTickets       = isLoadingTicketOver(F);

const ticketSlice = createSlice({
  name : "ticket",
  initialState : {
    isCreatingTicket : false,
    isLoadingTickets : false,
    tickets          : [],
  },
  reducers: {},
  extraReducers: builder => builder
    .addCase(asyncTicketActions.createTicket.pending,   creatingTicket)
    .addCase(asyncTicketActions.createTicket.rejected,  creatingTicket)
    .addCase(asyncTicketActions.createTicket.fulfilled, (state, { payload }) => {
      state.isCreatingTicket = false;
      state.tickets.push(payload);
    })

    .addCase(asyncTicketActions.getTickets.pending,   loadingTickets)
    .addCase(asyncTicketActions.getTickets.rejected,  loadedTickets)
    .addCase(asyncTicketActions.getTickets.fulfilled, ((state, { payload }) => ({
      state,
      tickets: payload.data
        .map((ticket) => ({
          ...state.tickets.find(({ id }) => id === ticket.id),
          ...ticket,
        }))
        .sort((a, b) => b.createdAt - a.createdAt)
    })))

    .addCase(asyncTicketActions.getTicketById.fulfilled, (state, { payload }) => ({
      ...state,
      tickets: state.tickets
        .filter(({ id }) => id !== payload.data.id)
        .concat({ ...state.tickets.find(({ id }) => id === payload.data.id), ...payload.data })
        .sort((a, b) => b.createdAt - a.createdAt)
    }))
    .addCase(asyncTicketActions.closeTicketById.fulfilled, (state, { payload }) => ({
      ...state,
      tickets: state.tickets.map((ticket) => ticket.id === payload.data ? ({
        ...ticket,
        stage: 4,
      })
      : ticket)
    }))

    .addCase(asyncTicketActions.createNestTicket.fulfilled, (state, { payload }) => ({
      ...state,
      tickets: state.tickets.map(ticket => {
        if (ticket.id === payload.data.parentId) {
          const nestTicket = ({ content: payload.data.content, createdAt: payload.data.createdAt });
          return ({
            ...ticket,
            nested: Array.isArray(ticket.nested)
              ? [...ticket.nested, nestTicket]
              : [nestTicket]
          });
        }
        return ticket;
      })
    }))
    .addCase(asyncTicketActions.createEmailTicket.fulfilled, (state, { payload, meta }) => {
      const ticketIndex = state.tickets.findIndex(({ id }) => id === meta.arg.ticketId)
      if (ticketIndex > -1) {
        state.tickets[ticketIndex].emails = payload;
      }
    })
  ,
});

const ticketStore = Object.freeze(Object.setPrototypeOf(ticketSlice, {
  asyncActions: asyncTicketActions,
}));

export default ticketStore;
