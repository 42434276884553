// CORE
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const withErrorState = (WrappedComponent) => (props) => {
  const [hasError, setHasError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (hasError) {
      setHasError(false);
    }
  }, [location.key]);


  return <WrappedComponent navigate={navigate} hasError={hasError} setHasError={setHasError} {...props}/>
}

export default withErrorState;
