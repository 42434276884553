// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import sizeService from '../../services/size';

const sizesAsyncActions = Object.freeze({
  getSizes : createAsyncThunk('get/sizes', sizeService.getSizes),
  getSizesByImageId : createAsyncThunk('get/sizesByImageId', sizeService.getSizesByImageId),
});

export default sizesAsyncActions;
