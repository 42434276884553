import { useEffect } from "react";
import { useSelector } from "react-redux";
import useFetchActualCostsByTeam from "./useFetchActualCostsByTeam";
import getId from "../../helpers/fp/models/getId";
import selectActualCostsByTeam from "../../store/actualCost/selectors";

const useActualCostsByTeam = (team) => {
  const fetchActualCostsByTeam = useFetchActualCostsByTeam();
  const actualCostsByTeam      = useSelector(selectActualCostsByTeam(team));

  const teamId = getId(team);
  useEffect(() => {
    if (teamId) {
      if (actualCostsByTeam.length > 0) requestIdleCallback(() => fetchActualCostsByTeam(teamId));
      else fetchActualCostsByTeam(teamId);
    }
  }, [teamId]);

  return actualCostsByTeam;
};

export default useActualCostsByTeam;
