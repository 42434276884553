// hooks
import useBillingSettingsByTeam               from "../../billingSettings/useBillingSettingsByTeam"
import useNotPaymentVerifiedByBillingSettings from "../useNotPaymentVerifiedByBillingSettings"

// helpers
import pipe from "../../../helpers/fp/composition/pipe"

const useNotPaymentVerifiedByTeam = pipe([
  useBillingSettingsByTeam,
  useNotPaymentVerifiedByBillingSettings,
]);

export default useNotPaymentVerifiedByTeam;
