import { useDispatch, useSelector } from "react-redux";
import teamStore from "../../store/team";

const useFetchTeams = () => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.userData);
  return () => profile && profile._id
    ? dispatch(teamStore.asyncActions.getTeams())
    : Promise.resolve(null);
}

export default useFetchTeams;
