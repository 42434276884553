// CORE
import React from "react";

// MUI
import { Box, Drawer } from "@mui/material";

// STYLES
import './dashboardSideBar.scss';

// COMPONENTS
import AccordionProjects from "./components/AccordionProjects/AccordionProjects";
import AccordionManage   from "./components/AccordionManage/AccordionManage";
import AccordionAccount  from "./components/AccordionAccount/AccordionAccount";
import AccordionFooter   from "./components/AccordionFooter/AccordionFooter";
import AccordionHSM      from "./components/AccordionHSM/AccordionHSM";

import BrandLogo from "../../../../shared/BrandLogo";

const DashboardSideBar = ({
  className,
}) => (
  <Box
    className={"side-bar" + (className ? " " + className : "")}
    component="nav"
    aria-label="mailbox folders"
  >
    <Drawer
      className="side-bar__drawer"
      variant="permanent"
      open
    >
      <Box paddingTop="calc(var(--offset) * 1.5)">
        <BrandLogo
          marginInline="auto"
          display="block"
          color="var(--color-white)"
          marginBottom="var(--offset)"
        />
        <AccordionProjects/>
        <AccordionManage/>
        <AccordionHSM/>
        <AccordionAccount/>
        <AccordionFooter/>
      </Box>
    </Drawer>
  </Box>
);

export default DashboardSideBar;
