import { useDispatch } from "react-redux";
import billingSettingsStore from "../../../store/billingSetting";
import trap from "../../../helpers/fp/decorators/trap";

const useFetchBillingSettingsByTeamId = () => {
  const dispatch = useDispatch();

  return trap((teamId) => (teamId)
    ? dispatch(billingSettingsStore.asyncActions.getBillingSettingsByTeam(teamId))
    : Promise.resolve(null)
  );
};

export default useFetchBillingSettingsByTeamId;
