import axiosInstance from "../axiosInstance";

const getNotifications = (params) => axiosInstance.get(`/notifications`, { params });
const markAsRead = ({ id }) => axiosInstance.put(`/notifications/${id}/read`);
const deleteNotification = ({ id }) => axiosInstance.delete(`/notifications/${id}`);

const notificationApi = Object.freeze({
  getNotifications,
  markAsRead,
  deleteNotification
});

export default notificationApi;
