// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import priceService from '../../services/price';

const pricesAsyncActions = Object.freeze({
  getPriceBySizeId : createAsyncThunk('_get/priceBySizeId', priceService.getPriceBySizeId),
});

export default pricesAsyncActions;
