// CORE
import { createSlice } from '@reduxjs/toolkit';
import notEmpty from '../../helpers/fp/array/notEmpty';
// ACTIONS
import asyncActions from './asyncActions';

const lifeTime = () => Date.now() - 3000;

/** @param {readonly [number, string][]} values */
const findIndexExpired = (values) => {
  const currentDate = lifeTime();
  return values.findIndex(cname => cname[0] < currentDate);
}

/** @param {readonly [number, string][]} values */
const filterLifeTime = (values) => {
  const index = findIndexExpired(values);
  return (index > -1) ? values.slice(0, index) : values;
}

/**
  * @param {readonly [number, string][]} values
  * @param {string} domainName
  */
const filterLifeTimeAndAddDomainName = (values, domainName) => notEmpty(values)
  ? filterLifeTime(values).concat([[Date.now(), domainName]])
  : [[Date.now(), domainName]]
;

const dnsSlice = createSlice({
    name : 'dns',
    initialState : {
      validCnames   : [],
      invalidCnames : [],
    },
    reducers : {
      recalculate (state) {
        if (notEmpty(state.validCnames))   state.validCnames   = filterLifeTime(state.validCnames);
        if (notEmpty(state.invalidCnames)) state.invalidCnames = filterLifeTime(state.invalidCnames);
        return state;
      },
    },
    extraReducers : builder => {
      builder
        .addCase(asyncActions.resolveCname.rejected, (state, { meta }) => {
          if (state.invalidCnames.includes(meta.arg)) return state;
          return ({
            ...state,
            invalidCnames: filterLifeTimeAndAddDomainName(state.invalidCnames, meta.arg),
          });
        })
        .addCase(asyncActions.resolveCname.fulfilled, (state, { meta }) => {
          if (state.validCnames.includes(meta.arg)) return state;
          return ({
            ...state,
            validCnames: filterLifeTimeAndAddDomainName(state.validCnames, meta.arg),
          });
        })
    },
});

const dnsStore = Object.freeze({
  ...dnsSlice,
  asyncActions,
});

export default dnsStore;
