// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import userActivityApi from '../../api/userActivity';

const userActivityAsyncActions = Object.freeze({
  getUserActivities : createAsyncThunk('get/userActivities', userActivityApi.getUserActivities),
});

export default userActivityAsyncActions;
