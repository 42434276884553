// hooks
import useBillingSettingsByTeamId from "../useBillingSettingsByTeamId";

// helpers
import pipe       from "../../../helpers/fp/composition/pipe";
import optionalId from "../../../helpers/fp/models/optionalId";
import lift       from "../../../helpers/fp/monads/lift";

const useBillingSettingsByTeam = pipe([optionalId, lift, useBillingSettingsByTeamId]);
export default useBillingSettingsByTeam;
