// CORE
import { Link } from "react-router-dom";
import React from "react";
// MUI
import {
  Accordion,
  AccordionDetails,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
// ICONS
import {
  ArrowTopIcon,
  ProductDocsIcon,
  SupportIcon,
} from "../../../../../../../assets/icons";
// CONSTANTS
import { SUPPORT_ROUTE } from "../../../../../../../constants/routes/routes";

const AccordionFooter = () => (
  <Accordion className="side-bar__accordion last-group">
    <AccordionDetails className="side-bar__details_footer">
      <List>
        <ListItem disablePadding>
          <a
            href="https://docs.enclaive.cloud/"
            target="_blank"
            rel="noreferrer"
          >
            <ListItemButton>
              <Box
                display="flex"
                alignItems="center"
                marginRight="8px"
              >
                <ProductDocsIcon />
              </Box>
              <ListItemText primary="Product Docs" />
              <Box marginLeft="8px">
                <ArrowTopIcon/>
              </Box>
            </ListItemButton>
          </a>
        </ListItem>
        <ListItem disablePadding>
          <Link
            to={SUPPORT_ROUTE}
          >
            <ListItemButton>
              <Box
                display="flex"
                alignItems="center"
                marginRight="8px"
              >
                <SupportIcon />
              </Box>
              <ListItemText primary="Support" />
              <Box marginLeft="8px">
                <ArrowTopIcon/>
              </Box>
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
    </AccordionDetails>
  </Accordion>
);

export default AccordionFooter
