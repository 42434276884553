// CORE
import { createSlice } from '@reduxjs/toolkit';
// ACTIONS
import asyncActions from './asyncActions';


const storageSlice = createSlice({
    name : 'virtualMachines',
    initialState : {},
    reducers : {
    },
    extraReducers : builder => {
      builder
        .addCase(asyncActions.get.fulfilled, (state, { meta, payload }) => ({
          ...state,
          [meta.arg]: payload.data
        }))
    },
});

const storageStore = Object.setPrototypeOf(storageSlice, {
  asyncActions,
});

export default storageStore;
