// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';

// SERVICES
import databaseService from '../../services/database';

const databasesAsyncActions = Object.freeze({
  createDatabase : createAsyncThunk('post/databases', databaseService.createDatabase),
  /** @depricated */
  getDatabases   : createAsyncThunk('get/databases', async (params) => databaseService.getDatabases(params)),
  deleteDatabase : createAsyncThunk('delete/databases', async (params) => databaseService.deleteDatabase(params)),
  cancelDatabase : createAsyncThunk('put/cancelDatabase', async (params) => databaseService.cancelDatabase(params)),

  getByTeam      : createAsyncThunk('get/databases/team',    databaseService.getByTeam),
  getByProject   : createAsyncThunk('get/databases/project', databaseService.getByProject),
});

export default databasesAsyncActions;
