// CORE
import { createAsyncThunk } from '@reduxjs/toolkit';
// SERVICES
import imageService from '../../services/image';


const imagesAsyncActions = Object.freeze({
  getImagesByProvider : createAsyncThunk('get/imagesByProvider', imageService.getImagesByProvider),
});

export default imagesAsyncActions;
