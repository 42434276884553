import { userStatuses } from "../../../../../constants/user";
import isPast from "../../../date/isPast";
import userIsDeactivated from "../isDeactivated";

const isSuspendedUser = (user) => user && (
  ((user.status === userStatuses.suspended) || isPast(user.suspendedAt)) ||
  userIsDeactivated(user)
);

export default isSuspendedUser;
