import React from "react";

import "./style.scss";
import { Box, IconButton, Modal, Typography } from "@mui/material";


const ModalWindow = ({
  children,
  open,
  setOpen,
  title,
  sx,
}) => (
  <Modal
    open={open}
    onClose={() => setOpen(false)}
    sx={{
      display        : 'flex',
      alignItems     : 'center',
      justifyContent : 'center',
      ...sx,
    }}
  >
    <Box
      backgroundColor="white"
      border="1px solid rgba(210, 210, 210, 0.9)"
      borderRadius=".25em"
      className="modal-window-base"
      display="flex"
      flexDirection="column"
      gap=".5em"
      padding="1.25em 1.5em"
      maxHeight="100vh"
      sx={{
        overflowY : 'auto',
        overflowX : 'hidden',
      }}
    >
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        maxWidth="calc(100vw - 1.5rem)"
      >
        <Typography
          sx={{
            display: "inline",
          }}
        >
          {title}
        </Typography>
        <IconButton
          onClick={() => setOpen(false)}
          sx={{
            width: "1.5em",
            height: "1.5em",
          }}
        >
          &times;
        </IconButton>
      </Box>
      <Box>
        {children}
      </Box>
    </Box>
  </Modal>
);

export default ModalWindow;
