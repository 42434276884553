// CORE
import { createSlice } from '@reduxjs/toolkit';

import partition from 'lodash/fp/partition';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from '../../helpers/fp/array/empty';
import eq    from '../../helpers/fp/array/internal/eq';
import DataCache from '../../helpers/DataCache';
import getId from '../../helpers/fp/models/getId';

const byProviderId          = (providerId) => (value) => value.provider === providerId;
const partitionByProviderId = (providerId) => partition(byProviderId(providerId));

const {
  save,
  load
} = DataCache("images");

const imagesSlice = createSlice({
  name : 'images',
  initialState : load() || empty(),
  reducers : {
    clearImages: empty,
  },
  extraReducers : builder => builder
    .addCase(asyncActions.getImagesByProvider.fulfilled, (state, { payload, meta }) => {
      if (!payload.data.length) return state;
      if (!state.length)        return payload.data;

      const providerId = getId(meta.arg);
      const [byArgs, byStore] = partitionByProviderId(providerId)(state);
      if (eq(byArgs, payload.data)) return state;

      return save(byStore.concat(payload.data));
    })
});

const imagesStore = Object.freeze({
  ...imagesSlice,
  asyncActions,
});

export default imagesStore;

