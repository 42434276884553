// CORE
import { createSlice } from '@reduxjs/toolkit';

// ACTIONS
import asyncActions from './asyncActions';

// helpers
import empty from '../../helpers/fp/array/empty';
import getId from '../../helpers/fp/models/getId';

const actualCostSlice = createSlice({
  name          : 'actual-costs',
  initialState  : empty(),
  reducers      : {},
  extraReducers : builder => builder
    .addCase(asyncActions.getByTeam.fulfilled, (state, { payload, meta }) => {
      const teamId = getId(meta.arg);
      return state
        .filter(item => item.teamId !== teamId)
        .concat(payload.map(item => ({ ...item, teamId })));
      ;
    })
});

const actualCostStore = Object.freeze({
  ...actualCostSlice,
  asyncActions,
});

export default actualCostStore;
