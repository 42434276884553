// CORE
import React from "react";
import { Outlet } from "react-router-dom";
// MUI
import { Box } from "@mui/material";
// STYLES
import "./dashboardOutlet.scss"

const DashboardOutlet = () => (
  <Box className="dashboard-outlet">
    <Outlet/>
  </Box>
);

export default DashboardOutlet
