import { DOCS_ROUTE, SUPPORT_ROUTE } from "../../../../constants/routes/routes";

const isDisabled = true;
export const footerItems = [
  {
    title:'Blog',
    link:'/blog',
    isDisabled,
  },
  {
    title:'Pricing',
    link:'/pricing',
    isDisabled,
  },
  {
    title:'Careers',
    link:'/careers',
    isDisabled,
  },
  {
    title:'Terms',
    link:'/terms',
    isDisabled,
  },
  {
    title:'Privacy',
    link:'/privacy',
    isDisabled,
  },
  {
    title:'Status',
    link:'/status',
    isDisabled,
  },
  {
    title:'Tutorials',
    link:'/tutorials',
    isDisabled,
  },
  {
    title:'Refer your friends for €',
    link:'/refer',
    isDisabled,
  },
  {
    title : 'Support',
    link  : SUPPORT_ROUTE,
  },
  {
    title : 'Docs',
    link  : DOCS_ROUTE,
    isDisabled,
  },
]
