// CORE
import { Button, Paper, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";
import { Link } from "react-router-dom";

const NoMatch = () => {
  return (
    <Paper variant="outlined" square sx={{
      backgroundColor : "gray.200",
      backgroundImage : "url(\"/assets/maze-white.png\")",
      padding         : "1rem",
      color           : "#000000",
      height          : "100vh",
      display         : "flex",
      alignItems      : "center",
      justifyContent  : "center",
    }}>
      <Stack alignItems="center">
        <Typography variant="h1" fontWeight="bold" fontSize="12rem" color="primary"
                    sx={{ marginTop : "-1.5rem" }}>404</Typography>
        <Typography variant="h3">Page is not found!</Typography>
        <Button variant="contained" component={Link} to="/" sx={{ marginTop : "2rem" }}>Go to home page</Button>
      </Stack>
    </Paper>
  );
};

export default NoMatch;
